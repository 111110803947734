import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-lite',
  templateUrl: './breadcrumb-lite.component.html',
  styleUrls: ['./breadcrumb-lite.component.scss'],
})
export class BreadcrumbLiteComponent implements OnInit {
  @Input() breadcrumbSections: string[] = [];
  @Output() back: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() { }

  toBack(i: number) {
    if (i === 0) {
      this.back.emit();
    }
  }

}
