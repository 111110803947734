import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

  constructor(private utils: DpUtilsService, private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 404) {

          if (error?.error?.message) {
            this.utils.showToast({ message: error.error.message, cssClass: 'danger' });
          } else {
            this.utils.showToast({ message: this.translate.instant('errors.internal_error'), cssClass: 'danger' });
          }
        }

        return throwError(error);
      })
    );
  }

}
