import { Injectable } from '@angular/core';
import { Coordinates } from '@interfaces/rest/interfaces/zone';

export interface Destination {
  main: string;
  secondary: string;
  placeId: string;
}

export interface DestinationDetail {
  lat: number;
  lng: number;
  main: string;
  secondary: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor() { }


  async geolocation(searchValue: string) {
    const destination: Destination[] = [];
    return new Promise<Destination[]>(async resolve => {
      let input = searchValue || '';

      const bounds = new google.maps.LatLngBounds(
        // new google.maps.LatLng(39.21336405907106, 2.286124031276248),
        // new google.maps.LatLng(39.996529359153115, 3.529999646106621)
        new google.maps.LatLng(39.793267, 3.041932),
        new google.maps.LatLng(39.913267, 3.201932)
      );

      const autocompleteService = new google.maps.places.AutocompleteService();
      try {
        const result = await autocompleteService.getPlacePredictions(
          {
            input,
            bounds
          },
          (predictions, status) => {
            console.log('predictions', predictions);
            console.log('status', status);
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              predictions?.forEach((prediction) => {
                destination.push({
                  main: prediction.structured_formatting.main_text,
                  secondary: prediction.structured_formatting.secondary_text,
                  placeId: prediction.place_id
                });
              });
            }
            resolve(destination);
          });
        console.log('result', result);
      } catch (error) {
        console.log('error', error);

      }
    });
  }

  async getAddressByPlaceId(placeId: string): Promise<Coordinates | null> {
    return new Promise(async resolve => {

      const geocoder = new google.maps.Geocoder();
      try {
        await geocoder.geocode({ placeId }, (result: any, status: any) => {
          console.log('result', result);
          console.log('status', status);

          if (result?.[0]) {
            const coordinates: Coordinates = {
              lat: result?.[0].geometry.location.lat(),
              lng: result?.[0].geometry.location.lng()
            }
            resolve(coordinates);
          } else {
            resolve(null);
          }
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

  // async geolocation(searchValue: string) {
  //   return new Promise<any>((resolve, reject) => {
  //     if (searchValue && !searchValue.includes('mallorca')) {
  //       searchValue += ' mallorca';
  //     }
  //     const input = searchValue || '';
  //     const suggestions: any[] = [];
  //     const autocompleteService = new google.maps.places.AutocompleteService() as any;
  //     autocompleteService.getPlacePredictions(
  //       { input, componentRestrictions: { country: 'ES' } },
  //       (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
  //         if (status === google.maps.places.PlacesServiceStatus.OK) {
  //           predictions?.forEach((prediction) => {
  //             suggestions.push(prediction);
  //           });
  //           resolve(predictions);
  //         } else {
  //           reject(status);
  //         }
  //       });
  //   });
  // }





  // async getAddressByPlaceId(placeId: string): Promise<any> {
  //   return new Promise(async resolve => {

  //     const geocoder = new google.maps.Geocoder();
  //     try {
  //       await geocoder.geocode({ placeId }, (result: any, status: any) => {
  //         console.log('result', result);
  //         console.log('status', status);

  //         if (result?.[0]) {
  //           resolve(result?.[0]);
  //         } else {
  //           resolve(null);
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   });
  // }

  async getAddressByCoords(lat: number, lng: number): Promise<any> {
    const geocoder = new google.maps.Geocoder();

    return new Promise<void>(async (resolve, reject) => {
      await geocoder.geocode({ location: { lat, lng } }, (result: any, status: any) => {
        console.log('result', result);
        console.log('status', status);

        if (result?.[0]) {
          resolve(result?.[0]);
        } else {
          reject();
        }
      });
    })

  }

}
