import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private nav: NavController) { }

  async canActivateFn(): Promise<boolean> {
    return new Promise(async resolve => {
      const auth = getAuth();
      onAuthStateChanged(auth, async user => {
        console.log('user', user);
        if (user) {
          resolve(true);
        } else {
          resolve(false);
          this.nav.navigateRoot('login');
        }
      });
    });
  }
}
