import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'src/app/interfaces/others';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() columns?: Table[];
  @Input() lines?: any[];
  @Input() background?: string;
  @Input() limit?: number;
  @Input() pagination = false;
  @Input() maxPage?: number;
  @Input() currentPage?: number;
  @Input() total?: number;
  pagesList: number[] = [];
  sortParams?: {
    field: string;
    sortType: '' | 'asc' | 'desc'
  };

  @Output() doActionParent = new EventEmitter();
  @Output() paginacionParent = new EventEmitter();
  @Output() saveLines = new EventEmitter();
  @Output() sendSort = new EventEmitter();
  // weekDays = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
  showCheck = false;

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnChanges() {
    if (this.maxPage) {
      console.log(this.currentPage);
    }
    // this.isShowCheck();

    if (this.total) {
      this.calcPages();
    }
  }

  doAction(action: any, line: any, ev: any) {
    const sendData = { action, line, ev };
    this.doActionParent.emit(sendData);
  }

  changePage(num = 1, isPage = false) {
    this.paginacionParent.emit(isPage ? num : ((this.currentPage || 0) + (num)));
  }

  parseString(o: any, s?: string) {
    if (s) {
      s.split('.').forEach(value => o = (o ? o[value] : ''));
      return o || '-';
    } else {
      return '-';
    }
  }

  // isShowCheck() {
  //   if (this.columns?.filter(column => column?.type === 'check').length) {
  //     this.showCheck = true;
  //   } else {
  //     this.showCheck = false;
  //   }
  // }

  calcPages() {
    this.pagesList = [];
    for (let i = 1; i <= this.total!; i++) {
      this.pagesList.push(i);
    }
  }

  toggleEvent(ev: any, selectedLine: any, field: any) {
    console.log('selectedLine', selectedLine);
    console.log('field', field);
    console.log('ev', ev.detail.checked);

    this.lines?.forEach(line => {
      if (line._id === selectedLine._id) {
        line[field] = ev.detail.checked;
      }
    });
    this.saveLines.emit(this.lines);
  }

  setString(ev: any, selectedLine: any, field: any) {
    // console.log('selectedLine', selectedLine);
    // console.log('field', field);
    // console.log('ev', ev.detail.value);

    this.lines?.forEach(line => {
      if (line._id === selectedLine._id) {
        line[field] = ev.detail.value;
      }
    });
    this.saveLines.emit(this.lines);
  }

  applySort(i: number) {
    if (this.columns?.[i].field !== this.sortParams?.field) {
      delete this.sortParams;
    }

    this.sortParams = {
      field: this.columns?.[i].field!,
      sortType: this.calcSortType()
    };
    this.sendSort.emit(this.sortParams);
  }

  calcSortType() {
    const { sortType } = this.sortParams || {};

    switch (sortType) {
      case '':
        return 'asc';

      case 'asc':
        return 'desc';

      case 'desc':
        return '';

      default:
        return 'asc';

    }
  }

}
