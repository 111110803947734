import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Coordinates, TriggerCoordinates, Zone } from '@interfaces/rest/interfaces/zone';
import { ProvinceService } from '@services/province.service';

import { MapConfiguration } from '@static_data/maps';

@Component({
  selector: 'app-google-map-general-secondary',
  templateUrl: './google-map-general-secondary.component.html',
  styleUrls: ['./google-map-general-secondary.component.scss'],
})
export class GoogleMapGeneralSecondaryComponent implements OnInit, OnChanges {
  @Input() zones: Zone[] = [];
  centerMap?: Coordinates;
  @ViewChild('map') mapElem?: any;

  // Map
  map?: google.maps.Map;
  zonesDraws: google.maps.Polygon[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private province: ProvinceService
  ) { }

  async ngOnChanges() {
    if (!this.centerMap) {
      this.centerMap = await this.province.getCenterMap();
    }
    this.cd.detectChanges();
    this.setZones();
  }

  async ngOnInit() {
    if (!this.centerMap) {
      this.centerMap = await this.province.getCenterMap();
    }
    this.loadMap();
  }

  loadMap() {
    const mapOptions = MapConfiguration;
    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElem.nativeElement, {
        center: { lat: this.centerMap!.lat, lng: this.centerMap!.lng },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        clickableIcons: false,
        disableDefaultUI: true,
        ...mapOptions,
      });
      this.setZones();
    }, 0);

  }

  setZones() {
    if (this.map) {
      for (const polygon of this.zonesDraws) {
        polygon.setMap(null); // Elimina el polígono del mapa
      }
      this.zonesDraws = [];
      this.zones.forEach(zone => {
        if (!zone.exclude && zone?.coordinates) {
          this.drawZones(zone.coordinates, zone.color);
          if (zone.triggerCoordinates) {
            const coordinates = this.getTriggerTime(zone.triggerCoordinates)
            if (coordinates) {
              this.drawZones(this.getTriggerPoligon(coordinates), zone.color);
            }
          }
        }
      });
    }
  }

  getTriggerTime(triggerCoordinates: TriggerCoordinates[]) {
    let coordinates: Coordinates | undefined = undefined;
    triggerCoordinates.forEach(triggerPoint => {
      const triggerTime = triggerPoint.time;
      const [startTime, endTime] = triggerTime.split('_');

      const now = new Date();
      const startDate = new Date(now);
      const endDate = new Date(now);

      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);
      startDate.setHours(startHours, startMinutes, 0, 0);
      endDate.setHours(endHours, endMinutes, 0, 0);

      if (now >= startDate && now <= endDate) {
        coordinates = triggerPoint.coordinates;
      }
    });
    return coordinates;
  }

  getTriggerPoligon(center: Coordinates, distance = 50): Coordinates[] {
    // Convertir distancia de metros a grados aproximados
    let latDistance = distance / 111320;
    let lngDistance = distance / (111320 * Math.cos(center.lat * (Math.PI / 180)));

    // Calcular las esquinas del cuadrado
    let topLeft = { lat: center.lat + latDistance, lng: center.lng - lngDistance };
    let topRight = { lat: center.lat + latDistance, lng: center.lng + lngDistance };
    let bottomRight = { lat: center.lat - latDistance, lng: center.lng + lngDistance };
    let bottomLeft = { lat: center.lat - latDistance, lng: center.lng - lngDistance };

    // Devolver el polígono como un arreglo de puntos
    return [topLeft, topRight, bottomRight, bottomLeft, topLeft]; // Cerramos el polígono añadiendo el punto inicial al final
  }

  drawZones(coordinates: Coordinates[], color?: string) {
    var polygon = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: color || '#a7d3e2',
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color || '#a7d3e2',
      fillOpacity: 0.1
    });

    // Add the polygon to the map
    polygon.setMap(this.map!);
    this.zonesDraws.push(polygon);
  }


}
