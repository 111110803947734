import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabsList: string[] = [];
  @Input() selectedtab!: string;
  @Input() translatePath?: string;

  @Output() sendSelectedTab: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  selectTab(tab: string) {
    this.sendSelectedTab.emit(tab);
  }

}
