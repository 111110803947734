export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyD4whYZk6F97tIkSQVdvezod2XVISah6GY',
    authDomain: 'taxi-mallorca-develop.firebaseapp.com',
    projectId: 'taxi-mallorca-develop',
    storageBucket: 'taxi-mallorca-develop.appspot.com',
    messagingSenderId: '214034210798',
    appId: '1:214034210798:web:717ecede6d1adba0f138ff',
    measurementId: 'G-MRFRSEQWTQ'
  },
  language: {
    defaultLang: 'es',
    available: ['es']
  },
  apiMainUrl: 'https://main-develop.mallorcab.com',
  apiCalculatorUrl: 'https://calculator-develop.mallorcab.com',
  socketMainUrl: 'wss://main-develop.mallorcab.com/',
  socketCalculatorUrl: 'wss://calculator-develop.mallorcab.com/',
  configurationId: 1
};
