import { Component, OnInit } from '@angular/core';
import { User } from '@interfaces/user';
import { AnalyticsService, ParamType } from '@services/analytics.service';
import { AuthService } from '@services/auth.service';
import { RestService } from '@services/rest.service';
import { SubscribeEventsService } from '@services/subscribe-events.service';

@Component({
  selector: 'app-lateral-menu',
  templateUrl: './lateral-menu.component.html',
  styleUrls: ['./lateral-menu.component.scss'],
})
export class LateralMenuComponent implements OnInit {
  pagesList: string[] = [];
  currentUser?: User;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private subscribeEv: SubscribeEventsService,
    private analytics: AnalyticsService
  ) {
    this.subscribeEv.observeUser().subscribe(async user => {
      console.log('user', user);
      if (user) {
        await this.getCurrentUser(true);
        this.createMenuSections();
      }
    });
  }

  async ngOnInit() {
    await this.getCurrentUser();
    this.createMenuSections();
  }

  async getCurrentUser(force = false) {
    const resp = await this.rest.getCurrentUser(force);
    if (resp) {
      this.currentUser = resp;
    }
  }

  createMenuSections() {
    if (this.currentUser?.role?.includes('manager')) {
      this.pagesList = [
        '',
        'services',
        'scheduled',
        'taxi',
        'drivers',
        'sessions',
        'clients',
        'hotels',
        'messages',
        'provinces',
        'zones',
        'delivery-system',
        'incidents',
        'archive'
      ];
    } else if (this.currentUser?.role?.includes('admin')) {
      this.pagesList = [
        // '',
        // 'services',
        // 'taxi',
        // 'scheduled',
        // 'clients',
        // 'messages',
        // 'zones',
        // 'drivers',
        'managers',
        'provinces',
        'configuration',
        'control',
        // 'incidents',
        // 'sessions',
        // 'metrics',
        // 'history',
        // 'archive'
      ];
    }
  }

  logout() {
    // ANALYTICS
    const params: ParamType = {
      currentUserId: this.currentUser!.id
    };
    this.analytics.sendEventByType('logout', params);
    this.auth.logout();
  }

}
