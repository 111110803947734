import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { getAuth, signInWithEmailAndPassword, signOut, confirmPasswordReset } from 'firebase/auth';

import { DpStorageService, DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { AnalyticsService } from './analytics.service';
import { RestService } from './rest.service';
import { environment } from '@environments/environment';
import { initializeApp } from 'firebase/app';

initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private analytics: AnalyticsService,
    private utils: DpUtilsService,
    private translate: TranslateService,
    private nav: NavController,
    private rest: RestService,
    private storage: DpStorageService
  ) {
  }

  getCurrentUser() {
    const auth = getAuth();
    return auth.currentUser;
  }

  async getToken() {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      return await currentUser.getIdToken();
    } else {
      return null;
    }
  }

  async confirmPasswordReset(oobCode: string, password: string) {
    try {
      const auth = getAuth();
      await confirmPasswordReset(auth, oobCode, password);
      return true;
    } catch (error: any) {
      console.error('Error confirm password reset', error);
      await this.utils.showToast({ message: this.translate.instant('auth.error.recovery_password_link_error'), cssClass: 'warning' })
      return false;
    }
  }

  async loginWithEmailAndPassword(email: string, password: string) {
    try {
      const auth = getAuth();
      const result = await signInWithEmailAndPassword(auth, email, password)
      await this.rest.getCurrentUser();
      const userId = result?.user?.uid;
      if (userId) {
        const resp = await this.rest.getCurrentUser();
        if (resp) {
          if (resp.role?.includes('admin')) {
            await this.nav.navigateRoot('/managers');
          } else if (resp.role?.includes('manager')) {
            await this.nav.navigateRoot('');
          }
        }

        return userId;
      } else {
        return false;
      }
    } catch (error: any) {
      console.error('error loginWithEmailAndPassword', error);
      await this.utils.showToast({
        header: '¡Error!', message: await (this.translate.instant('auth.error.firebase.' + error.code)), cssClass: 'danger'
      });
      return false;
    }
  }

  async logout() {
    try {
      console.log('logout user');
      const auth = getAuth();
      await signOut(auth);
      await this.storage.deleteItem('taxi_backoffice_current_user');

      this.analytics.sendEventByType('logout');
    } catch (error: any) {
      console.log(error);
      this.utils.showToast({ message: await this.translate.instant(`errorMessages.firebase.${error.code}`), cssClass: 'danger' });
    }
  }

}
