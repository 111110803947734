import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDefaultImage]'
})
export class DefaultImageDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('error')
  loadFallbackOnError() {
    const elem: HTMLImageElement = this.el.nativeElement as HTMLImageElement;
    elem.src = '/assets/images/empty.png';
    this.renderer.addClass(elem, 'empty-image');
  }

  ngAfterViewInit() {
    const elem: HTMLImageElement = this.el.nativeElement as HTMLImageElement;
    if (elem.src && elem.src.length < 20) {
      elem.src = '/assets/images/empty.png';
      this.renderer.addClass(elem, 'empty-image');
    }
  }
}

