import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SuggestionLocation } from '@interfaces/others';
import { Destination, LocationService } from '@services/location.service';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent {
  @Input() suggestions: any[] = [];
  @Output() sendData = new EventEmitter();

  constructor(private locationService: LocationService) { }

  async getAddressByPlaceId(suggestion: Destination) {
    const suggestionResult = await this.locationService.getAddressByPlaceId(suggestion.placeId);
    console.log('suggestionResult*****', suggestionResult);
    if (suggestionResult) {
      const location: SuggestionLocation = {
        lat: suggestionResult.lat,
        lng: suggestionResult.lng,
        main: suggestion.main,
        secondary: suggestion.secondary
      };

      console.log('location', location);
      this.sendData.emit(location);
      this.suggestions = [];
      // if (this.inputHasFocus === 'from') {

      //   this.filterToolbarConfig.left.inputs.forEach((input: FilterInputs) => {
      //     if (input.title === 'from') {
      //       input.selectedValue = location.lat + ', ' + location.lng
      //     }
      //   });
      //   // this.serviceForm.get('currentLocation')?.setValue(location);
      //   // this.serviceForm.get('currentLocationName')?.setValue(`${location.main}, ${location.secondary}`);
      //   this.currentLocationSuggestions = [];
      // } else {
      //   this.filterToolbarConfig.left.inputs.forEach((input: FilterInputs) => {
      //     if (input.title === 'to') {
      //       input.selectedValue = location.lat + ', ' + location.lng
      //     }
      //   });
      //   // this.serviceForm.get('destination')?.setValue(location);
      //   // this.serviceForm.get('destinationName')?.setValue(`${location.main}, ${location.secondary}`);
      //   this.destinationSuggestions = [];
      // }
    }

    // this.sendData.emit(this.serviceForm.value);
  }

}
