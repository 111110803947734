import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DigitupLibModule } from 'digitup-lib/dist/digitup-lib';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpRequestInterceptor } from './app/interceptors/http-request.interceptor';
import { HttpResponseInterceptor } from './app/interceptors/http-response.interceptor';

registerLocaleData(es);

if (environment.production) {
  enableProdMode();
}

export function httpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'assets/i18n/', suffix: '.json' }
  ]);
}

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return (params.interpolateParams as any).default || params.key;
    }
    return params.key;
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    importProvidersFrom(IonicModule.forRoot({ mode: 'ios', animated: false }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          // useFactory: (createTranslateLoader),
          useFactory: (httpLoaderFactory),
          deps: [HttpBackend]
        },
        missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper }
      }),
      HttpClientModule,
      DigitupLibModule),
    provideRouter(routes),
  ],
});
