<ion-list lines="none">
  <div class="item" *ngFor="let item of items; let i = index; let last = last" [class.active]="activeIndex === i"
    [class.finished]="activeIndex > i" (click)="selectIndex(i)">
    <div class="index">
      <span *ngIf="activeIndex <= i; else checkIcon">{{i + 1}}</span>
      <ng-template #checkIcon>
        <ion-icon name="dp-check"></ion-icon>
      </ng-template>
    </div>
    <div class="name">{{ 'breadcrumb.' + item.label | translate: {default: item.label} }}</div>
    <!-- <ion-icon *ngIf="!last" name="chevron-forward"></ion-icon> -->
    <ion-icon *ngIf="(i + 1) !== items.length" name="dp-chevron-double-right"></ion-icon>
  </div>
</ion-list>