import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestService } from './rest.service';
import { ServiceInfo, ZoneInfo } from '@interfaces/rest/interfaces/zone';
import { License } from '@interfaces/rest/interfaces/licenses';
import { User } from '@interfaces/user';
import { PaginationParams } from '@interfaces/rest/interfaces/paginationParams';
import { Province } from '@interfaces/province';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {
  zoneInfo: ZoneInfo = {
    drivers: [],
    zones: []
  };
  provinceId?: number;
  province?: Province;

  updateZoneInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  zoneInfo$: BehaviorSubject<ZoneInfo | null> = new BehaviorSubject<ZoneInfo | null>(null);
  serviceInfo$: BehaviorSubject<ServiceInfo | null> = new BehaviorSubject<ServiceInfo | null>(null);

  lastZoneUpdate = new Date();

  licenseList: License[] = [];
  driversList: User[] = [];

  constructor(private rest: RestService) {
  }

  async getZonesFromApi(provinceId: number) {
    const resp = await this.rest.getZoneInfoByProvinceId(provinceId);
    if (resp.ok && resp.response?.zoneInfo) {
      await this.getLicenseList();
      this.zoneInfo.zones = resp.response.zoneInfo.zones;
      if (!this.zoneInfo.drivers.length && resp.response.zoneInfo.drivers) {
        this.zoneInfo.drivers = resp.response.zoneInfo.drivers;
      }
    }
  }

  async getLicenseList() {
    const result = await this.rest.getLicensesAll();
    if (result.ok) {
      const initLicenseList = result.response?.data?.result || [];
      if (!this.provinceId) {
        await this.getSetProvinceId();
      }
      this.licenseList = initLicenseList.filter(elem => elem.provinceId?.id === this.provinceId);
    }
  }

  async getSetProvinceId() {
    const result = await this.rest.getCurrentUser();
    if (result?.province?.id) {
      this.provinceId = result.province.id;
    }
  }

  async getLicenses() {
    if (this.licenseList.length === 0) {
      await this.getLicenseList();
    }
    return this.licenseList;
  }

  async getDriversList() {
    if (this.driversList.length === 0) {
      const params: PaginationParams = {
        limit: 100,
        'where.role': 'driver',
        'where.province.id': this.provinceId
      };
      const result = await this.rest.getUsersAll(params);
      if (result.ok) {
        this.driversList = result.response.data.result;
      }
    }
    return this.driversList;
  }

  async getZones(): Promise<ZoneInfo | undefined> {
    if (this.zoneInfo?.zones.length) {
      return this.zoneInfo;
    } else {
      if (this.provinceId) {
        await this.getZonesFromApi(this.provinceId);
      } else {
        const user = await this.rest.getCurrentUser();
        if (user?.province) {
          this.provinceId = user.province.id;
          return this.getZones();
        }
      }
      return this.zoneInfo;
    }
  }

  async updateZone(zoneInfo: ZoneInfo) {
    if (this.zoneInfo) {
      this.zoneInfo.drivers = zoneInfo.drivers;
      this.zoneInfo.drivers.forEach(elem => {
        const findLicense = this.licenseList.find(elemLL => elemLL.id === elem.licenseId);
        if (findLicense) {
          elem.license = findLicense;
        }
      });
      this.zoneInfo$.next(this.zoneInfo);

      if (this.provinceId) {
        const fiveMinutes = 5 * 60 * 1000;
        if (new Date().getTime() - this.lastZoneUpdate.getTime() > fiveMinutes) {
          this.lastZoneUpdate = new Date();
          await this.getZonesFromApi(this.provinceId);
        }
      }
    }
    if (!this.zoneInfo.zones.length) {
      await this.getZones();
    }
  }

  updateService(serviceInfo: ServiceInfo) {
    this.serviceInfo$.next(serviceInfo);
  }

  async getDrivers() {
    if (!this.zoneInfo.zones.length) {
      await this.getZones();
    }
    return this.zoneInfo?.drivers;
  }

  async getZoneNameById(id: string) {
    await this.getZones();
    const zoneInfo = this.zoneInfo?.zones.find(zone => zone?._id === id);
    return zoneInfo ? (zoneInfo.name || '') : '';
  }

  async getProvince() {
    if (!this.province) {
      if (!this.provinceId) {
        await this.getSetProvinceId();
      }
      const result = await this.rest.getProvinceById(this.provinceId!);
      if (result.ok) {
        this.province = result.response;
      }
    }
    return this.province;
  }

  async getCenterMap() {
    const defaultCenter = {
      lat: 39.846371,
      lng: 3.124781
    }

    if (!this.province) {
      await this.getProvince();
    }

    if (this.province?.config?.centerMap) {
      return this.province?.config?.centerMap;
    } else {
      return defaultCenter;
    }

  }

}
