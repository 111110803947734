import { Injectable } from '@angular/core';
import { JourneyStatus } from '@interfaces/rest/interfaces/journey';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  getCurrentTime(date: Date, minute = false) {
    if (!date) {
      return '';
    }

    const journey = new Date(date).getTime();
    const current = new Date().getTime();

    if (minute) {
      var differenceInMilliseconds = current - journey;
      var differenceInMinutes = differenceInMilliseconds / (1000 * 60);
      return differenceInMinutes.toFixed(0);
    } else {
      const diff = new Date(current - journey);
      // Вычисляем часы, минуты и секунды
      const hours = diff.getUTCHours();
      const minutes = diff.getUTCMinutes();
      const seconds = diff.getUTCSeconds();

      // Форматируем часы, минуты и секунды, чтобы они всегда содержали две цифры
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return hours > 0
        ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        : `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  getColor(status: JourneyStatus) {
    let color = '';
    if (['CLOSED', 'CANCELED_BY_USER', 'CANCELED_BY_DRIVER', 'CANCELED_BY_MANAGER', 'CANCEL', 'EXPIRED', 'ERROR'].includes(status)) {
      color = 'red';
    } else if (['TRIP_COMPLETED'].includes(status)) {
      color = 'green';
    } else if (['RECURRENT_RESERVE_NEW', 'RESERVE_WAITING'].includes(status)) {
      color = 'blue';
    } else if (['PENDING'].includes(status)) {
      color = 'orange';
    }
    return color;
  }
}
