import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapComponent } from './google-map/google-map.component';
import { LateralMenuComponent } from './lateral-menu/lateral-menu.component';
import { HeaderComponent } from './header/header.component';
import { HomeSearchBarComponent } from './home-search-bar/home-search-bar.component';
import { TableComponent } from './table/table.component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbLiteComponent } from './breadcrumb-lite/breadcrumb-lite.component';
import { TabsComponent } from './tabs/tabs.component';
import { TableDotsPopoverComponent } from './table-dots-popover/table-dots-popover.component';
import { EditToolbarComponent } from './edit-toolbar/edit-toolbar.component';
import { AlertConfirmComponent } from './alert-confirm/alert-confirm.component';
import { SharedModule } from './shared.module';
import { GoogleMapGeneralComponent } from './google-map-general/google-map-general.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { GoogleMapGeneralSecondaryComponent } from './google-map-general-secondary/google-map-general-secondary.component';
import { DetailZoneComponent } from './detail-zone/detail-zone.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { WheelListComponent } from './wheel-list/wheel-list.component';
import { ActionWheelActivityComponent } from './action-wheel-activity/action-wheel-activity.component';


@NgModule({
  declarations: [
    GoogleMapComponent,
    GoogleMapGeneralComponent,
    LateralMenuComponent,
    HeaderComponent,
    HomeSearchBarComponent,
    TableComponent,
    TableToolbarComponent,
    BreadcrumbComponent,
    BreadcrumbLiteComponent,
    TabsComponent,
    TableDotsPopoverComponent,
    EditToolbarComponent,
    AlertConfirmComponent,
    SuggestionsComponent,
    GoogleMapGeneralSecondaryComponent,
    DetailZoneComponent,
    ViewImageComponent,
    WheelListComponent,
    ActionWheelActivityComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
    GoogleMapComponent,
    GoogleMapGeneralComponent,
    LateralMenuComponent,
    HeaderComponent,
    HomeSearchBarComponent,
    TableComponent,
    TableToolbarComponent,
    BreadcrumbComponent,
    BreadcrumbLiteComponent,
    TabsComponent,
    TableDotsPopoverComponent,
    EditToolbarComponent,
    AlertConfirmComponent,
    SuggestionsComponent,
    GoogleMapGeneralSecondaryComponent,
    DetailZoneComponent,
    WheelListComponent,
    ActionWheelActivityComponent
  ]
})
export class ComponentsModule { }
