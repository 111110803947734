import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderButton, HeaderConfig } from '@interfaces/header-config';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() headerConfig?: HeaderConfig;
  @Output() buttonsEvent: EventEmitter<HeaderButton> = new EventEmitter();

  constructor(private nav: NavController) { }

  buttonsAction(button: HeaderButton, ev: any) {
    button.event = ev;
    this.buttonsEvent.emit(button);
  }

  back() {
    this.nav.back();
  }

}
