<div class="table_toolbar_box">
  <ng-container *ngFor="let side of sidesList">
    <div *ngIf="filterToolbarConfig?.[side]?.inputs?.length"
      class="side {{side}} {{filterToolbarConfig?.[side]?.cssClass}}">
      <ng-container *ngFor="let input of filterToolbarConfig?.[side]?.inputs">

        <ion-select *ngIf="input.type === 'select'" interface="popover" [class]="input.cssClass"
          [value]="input.selectedValue" [multiple]="input.typeMulti ? input.typeMulti : false"
          [placeholder]="input.translatePath + '.' + input.title | translate: {default: input.title}"
          (ionChange)="selectEvent($event, input)">
          <ion-select-option *ngFor="let option of input.values" [value]="option.id">
            {{ input.translatePath ? (input.translatePath + '.' + option.name | translate:{default: option.name}) :
            option.name}}
          </ion-select-option>
        </ion-select>

        <div *ngIf="input.type === 'search'" class="search_box">
          <ion-icon name="dp-search"></ion-icon>
          <ion-input [class]="input.cssClass" [debounce]="1200" [value]="input.selectedValue"
            [placeholder]="input.translatePath + '.' + input.title | translate: {default: input.title}"
            (ionChange)="selectEvent($event, input)"></ion-input>
        </div>

        <ion-button *ngIf="input.type === 'button'" [class]="input.cssClass" fill="clear" (click)="buttonAction()">
          {{input.translatePath + '.' + input.title | translate:{default: input.title} }}</ion-button>

        <ng-container *ngIf="['date', 'datetime'].includes(input.type)">
          <ion-datetime-button [datetime]="'datetime-' + input.title + '_' + uniqueNumber">
            {{input.selectedValue || (input.translatePath + '.' + input.title | translate: {default: input.title})}}
          </ion-datetime-button>

          <ion-modal [keepContentsMounted]="true">
            <ng-template>
              <ion-datetime color="rose" [id]="'datetime-' + input.title + '_' + uniqueNumber"
                (ionChange)="selectEvent($event, input)" display-format="D MMM YYYY" picker-format="D MMM YYYY"
                [presentation]="input.type === 'date' ? 'date' : 'date-time'" [min]="input?.min || undefined"
                [max]="input?.max || undefined" [value]="input.selectedValue || undefined">
              </ion-datetime>
            </ng-template>
          </ion-modal>
        </ng-container>

        <div class="geolocation_box" *ngIf="input.type === 'geolocation'">
          <ion-input [class]="input.cssClass" [debounce]="500" [value]="input.selectedValue"
            [placeholder]="input.translatePath + '.' + input.title | translate: {default: input.title}"
            (ionInput)="getSuggestions(input.title, $event); inputHasFocus = input.title"
            (click)="inputHasFocus = input.title"></ion-input>

          <div
            *ngIf="(currentLocationSuggestions.length && input.title === 'from' && inputHasFocus === 'from') || (destinationSuggestions.length && input.title === 'to' && inputHasFocus === 'to')"
            class="suggestions_box">
            <ng-container *ngIf="currentLocationSuggestions.length">
              <app-suggestions *ngIf="currentLocationSuggestions.length" [suggestions]="currentLocationSuggestions"
                (sendData)="inputHasFocus = 'from'; saveSuggestion($event)">
              </app-suggestions>
              <!-- <div class="destination"
                *ngFor="let suggestion of (currentLocationSuggestions.length ? currentLocationSuggestions :  destinationSuggestions)"
                (click)="getAddressByPlaceId(suggestion); inputHasFocus = input.title">
                <ion-icon name="dp-location"></ion-icon>
                <div class="info">
                  <div class="name">{{suggestion.main}}</div>
                  <div class="location">{{suggestion.secondary}}</div>
                </div>
              </div> -->
            </ng-container>

            <ng-container *ngIf="destinationSuggestions.length  && input.title === 'to'">
              <!-- <div class="destination"
                *ngFor="let suggestion of (currentLocationSuggestions.length ? currentLocationSuggestions :  destinationSuggestions)"
                (click)="getAddressByPlaceId(suggestion); inputHasFocus = input.title">
                <ion-icon name="dp-location"></ion-icon>
                <div class="info">
                  <div class="name">{{suggestion.main}}</div>
                  <div class="location">{{suggestion.secondary}}</div>
                </div>
              </div> -->
              <app-suggestions *ngIf="destinationSuggestions.length" [suggestions]="destinationSuggestions"
                (sendData)="inputHasFocus = 'to'; saveSuggestion($event)">
              </app-suggestions>
            </ng-container>

          </div>
        </div>

      </ng-container>
    </div>
  </ng-container>
</div>