<div class="header_box">
  <div class="left_side">
    <div class="logo_box">
      <ion-menu-button></ion-menu-button>
    </div>

    <div class="text-2xl-font-bold title" *ngIf="headerConfig?.title?.text">
      <ion-icon *ngIf="headerConfig?.showBack" name="dp-back-2" (click)="back()"></ion-icon>
      {{headerConfig?.title?.translatePath + '.' + headerConfig?.title?.text| translate: {default: headerConfig?.title?.text} }}
    </div>
  </div>

  <div *ngIf="headerConfig?.buttons" class="buttons_box">
    <ion-button fill="clear" [class]="button?.class || 'general'" *ngFor="let button of headerConfig?.buttons"
      (click)="buttonsAction(button, $event)">
      <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
      {{button.translatePath + '.' + button.text | translate: {default: button.text} }}
    </ion-button>
  </div>

</div>