<div class="buttons_box">
  <ion-button mode="ios" fill="clear" class="secondary" size="small" (click)="isEdit = false; action('edit')">
    <ion-icon name="dp-close"></ion-icon>
    {{'general.cancel' | translate}}
  </ion-button>

  <ion-button *ngIf="!lite" mode=" ios" fill="clear" class="secondary" size="small" (click)="action('delete')">
    <ion-icon name="dp-trash"></ion-icon>
    {{'general.delete' | translate}}
  </ion-button>

  <ion-button mode="ios" fill="clear" class="general" size="small" (click)="action('save')">
    <ion-icon name="dp-check"></ion-icon>
    {{'general.save' | translate}}
  </ion-button>
</div>