import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss'],
})
export class AlertConfirmComponent {
  translatePath?: string;
  message!: string;
  icon?: string;

  constructor(private modalController: ModalController) { }

  async close(result = false) {
    await this.modalController.dismiss(result);
  }

}
