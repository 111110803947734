import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribeEventsService {
  userReady: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  taxisReady: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);

  constructor() { }

  checkUser() {
    this.userReady.next(true);
  }
  observeUser() {
    return this.userReady.asObservable();
  }

  checkTaxis() {
    this.taxisReady.next(true);
  }

  observeTaxis() {
    return this.taxisReady.asObservable();
  }


}
