import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Network } from '@capacitor/network';
import { ComponentsModule } from '@components/components.module';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { initializeApp } from 'firebase/app';
import { environment } from '@environments/environment';
import { RestService } from '@services/rest.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { SocketService } from '@services/socket.service';

initializeApp(environment.firebaseConfig);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterLink, RouterLinkActive, CommonModule, ComponentsModule],
})
export class AppComponent {
  currentNetworkStatus = true;
  actived?: string;
  simplePage = false;

  constructor(
    private translate: TranslateService,
    private utils: DpUtilsService,
    private router: Router,
    private rest: RestService,
    private socketService: SocketService
  ) {
    this.init();
  }

  init() {
    this.translate.use('es');
    this.enableNetwork();
    this.checkCurrentPage();
    this.getCurrentUser();
  }

  async getCurrentUser() {
    const auth = getAuth();
    onAuthStateChanged(auth, async user => {
      console.log('user', user);
      if (user) {
        await this.rest.getCurrentUser(true);
        this.socketService.initSocket(user);

        setTimeout(() => {
          console.log('Listner main inited', this.socketService.listnerMainInited);
          console.log('Listner alculator inited', this.socketService.listnerCalculatorInited);
        }, 3000);
      }
    });
  }

  async enableNetwork() {
    console.log('enable network');
    const statusStart = await Network.getStatus();
    if (statusStart && !statusStart.connected) {
      await this.utils.showToast({ message: 'Problemas con conexión a Internet', cssClass: 'warning' });
    }
    Network.addListener('networkStatusChange', async (status) => {
      console.log('Network status changed', status);
      if (status && !status.connected && this.currentNetworkStatus) {
        this.currentNetworkStatus = false;
        await this.utils.showToast({ message: 'Problemas con conexión a Internet', cssClass: 'warning' });
      } else if (status && status.connected && !this.currentNetworkStatus) {
        this.currentNetworkStatus = true;
      }
    });
  }

  checkCurrentPage() {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.actived = event.url.split('/')[1].split('#')[0];
        if (['login'].includes(this.actived) ||
          ['reset-password'].includes(this.actived) ||
          ['create-driver'].includes(this.actived) ||
          ['create-manager'].includes(this.actived) ||
          this.actived.includes('action')
        ) {
          this.simplePage = true;
        } else {
          this.simplePage = false;
        }
      }
    });
  }

}
