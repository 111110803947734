import { NgModule } from '@angular/core';
import { DefaultImageDirective } from '@directives/default-image.directive';
import { TimeElapsedPipe } from '@pipes/time-elapsed.pipe';

@NgModule({
  exports: [
    DefaultImageDirective,
    TimeElapsedPipe
  ],
  declarations: [
    DefaultImageDirective,
    TimeElapsedPipe
  ]
})
export class SharedModule { }
