<ion-content>

  <ng-container *ngIf="paradaName">
    <ion-header class="modal-header">
      <ion-toolbar>
        <div class="title-container">
          <div class="title"><span *ngIf="paradaName">{{paradaName}}</span></div>
          <div class="close" (click)="close()">
            <ion-icon name="dp-close"></ion-icon>
          </div>
        </div>
        <div class="total-container">
          <div class="total" translate> wheel_modal.total </div>
          <div class="count">{{ parada.length }}</div>
        </div>
        <div class="row-title">
          <div class="text" translate> wheel_modal.license </div>
          <div class="time" translate> wheel_modal.time </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="container">
      <div class="data-container">
        <div class="row row_parada" *ngFor="let row of parada; let i = index" (click)="dislocate(row)">
          <div class="text">{{ i + 1 }}º - <span>{{ row.text }}</span></div>
          <div class="time" *ngIf="row.time"> {{ row.time | timeElapsed }} </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ion-header class="modal-header">
    <ion-toolbar>
      <div class="title-container">
        <div class="title"><span *ngIf="zoneName">{{zoneName}}</span></div>
        <div class="close" *ngIf="!paradaName" (click)="close()">
          <ion-icon name="dp-close"></ion-icon>
        </div>
      </div>
      <div class="total-container">
        <div class="total" translate> wheel_modal.total </div>
        <div class="count">{{ zone.length }}</div>
      </div>
      <div class="row-title">
        <div class="text" translate> wheel_modal.license </div>
        <div class="time" translate> wheel_modal.time_zone </div>
      </div>
    </ion-toolbar>
  </ion-header>


  <div class="container">
    <div class="data-container">
      <div class="row" *ngFor="let row of zone; let i = index">
        <div class="text">{{ i + 1 }}º - <span>{{ row.text }}</span></div>
        <div class="time" *ngIf="row.time"> {{ row.time | timeElapsed }} </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="target.length">
    <ion-header class="modal-header">
      <ion-toolbar>
        <div class="title-container">
          <div class="title"><span>Descarga</span></div>
        </div>
        <div class="total-container">
          <div class="total" translate> wheel_modal.total </div>
          <div class="count">{{ target.length }}</div>
        </div>
        <div class="row-title">
          <div class="text" translate> wheel_modal.license </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="container">
      <div class="data-container">
        <div class="row" *ngFor="let row of target; let i = index">
          <div class="text">{{ i + 1 }}º - <span>{{ row.text }}</span></div>
        </div>
      </div>
    </div>
  </ng-container>
</ion-content>