import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestService } from '@services/rest.service';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { ConfirmAlertOptions } from 'digitup-lib/dist/digitup-lib/lib/interfaces/other';

export type WheelInfoType = 'zone' | 'parada' | 'target';

export interface WheelModalData {
  id: string;
  text: string;
  time?: Date;
  type?: WheelInfoType;
  mongoSessionId?: string;
}

@Component({
  selector: 'app-detail-zone',
  templateUrl: './detail-zone.component.html',
  styleUrls: ['./detail-zone.component.scss'],
})
export class DetailZoneComponent implements OnInit {
  @Input() config!: WheelModalData[];
  @Input() zoneName?: string;
  @Input() paradaName?: string;

  zone: WheelModalData[] = [];
  parada: WheelModalData[] = [];
  target: WheelModalData[] = [];

  currentTime: Date = new Date();

  constructor(
    private modalController: ModalController,
    private utils: DpUtilsService,
    private rest: RestService
  ) { }

  ngOnInit() {
    this.zone = this.config.filter(elem => elem.type === 'zone');
    this.parada = this.config.filter(elem => elem.type === 'parada');
    this.target = this.config.filter(elem => elem.type === 'target');
    console.log('this.target', this.config);
    console.log('this.target', this.target);
  }

  close() {
    this.modalController.dismiss(null)
  }

  async dislocate(row: WheelModalData) {
    console.log('row', row);
    const alertConfig: ConfirmAlertOptions = {
      header: 'relocate.title',
      message: 'relocate.subtitle',
      cssClass: 'relocate',
      wait: true,
      cancel: true,
      buttonOk: 'general.yes',
      buttonCancel: 'general.no'
    }

    const result = await this.utils.showAlert(alertConfig)

    console.log('result', result);
    console.log('row?.mongoSessionId', row?.mongoSessionId);

    if (result && row?.id) {
      await this.utils.showLoading();
      await this.rest.changeQueuePosition(row?.id);
      await this.utils.hideLoading();
    }
  }

}
