import { Component, Input, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-table-dots-popover',
  templateUrl: './table-dots-popover.component.html',
  styleUrls: ['./table-dots-popover.component.scss'],
})
export class TableDotsPopoverComponent {
  @Input() options?: {
    name: string;
    icon?: string;
    color?: string
  }[];

  @Input() translatePath?: string;

  constructor(private popoverController: PopoverController) { }

  select(option: any) {
    this.popoverController.dismiss(option);
  }

}
