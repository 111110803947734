import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'services',
    loadComponent: () => import('./pages/services/services.page').then(m => m.ServicesPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'services/new-service',
    loadComponent: () => import('./pages/services/pages/new-service/new-service.page').then(m => m.NewServicePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'services/edit-service/:id',
    loadComponent: () => import('./pages/services/pages/new-service/new-service.page').then(m => m.NewServicePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'services/service-detail/:id',
    loadComponent: () => import('./pages/services/pages/service-detail/service-detail.page').then(m => m.ServiceDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'scheduled',
    loadComponent: () => import('./pages/scheduled/scheduled.page').then(m => m.ScheduledPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'scheduled/new-service',
    loadComponent: () => import('./pages/services/pages/new-service/new-service.page').then(m => m.NewServicePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()],
    data: { scheduled: true }
  },
  {
    path: 'scheduled/edit-service/:id',
    loadComponent: () => import('./pages/services/pages/new-service/new-service.page').then(m => m.NewServicePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()],
    data: { scheduled: true }
  },
  {
    path: 'scheduled/service-detail/:id',
    loadComponent: () => import('./pages/services/pages/service-detail/service-detail.page').then(m => m.ServiceDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()],
    data: { scheduled: true }
  },
  {
    path: 'taxi',
    loadComponent: () => import('./pages/taxi/taxi.page').then(m => m.TaxiPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'taxi/taxi-detail/:id',
    loadComponent: () => import('./pages/taxi/pages/taxi-detail/taxi-detail.page').then(m => m.TaxiDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'taxi/new-taxi',
    loadComponent: () => import('./pages/taxi/pages/taxi-detail/taxi-detail.page').then(m => m.TaxiDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'clients',
    loadComponent: () => import('./pages/clients/clients.page').then(m => m.ClientsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()],
    data: { hotels: false }
  },
  {
    path: 'hotels',
    loadComponent: () => import('./pages/clients/clients.page').then(m => m.ClientsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()],
    data: { hotels: true }
  },
  {
    path: 'clients/client-detail/:id',
    loadComponent: () => import('./pages/clients/pages/client-detail/client-detail.page').then(m => m.ClientDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'clients/edit-client/:id',
    loadComponent: () => import('./pages/clients/pages/client-detail/client-detail.page').then(m => m.ClientDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'hotels/edit-client/:id',
    loadComponent: () => import('./pages/clients/pages/client-detail/client-detail.page').then(m => m.ClientDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'messages',
    loadComponent: () => import('./pages/messages/messages.page').then(m => m.MessagesPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'messages/message-detail/:id',
    loadComponent: () => import('./pages/messages/pages/message-detail/message-detail.page').then(m => m.MessageDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  }, {
    path: 'messages/new-message',
    loadComponent: () => import('./pages/messages/pages/message-detail/message-detail.page').then(m => m.MessageDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'zones',
    loadComponent: () => import('./pages/zones/zones.page').then(m => m.ZonesPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'zones/add-zone',
    loadComponent: () => import('./pages/zones/pages/add-zone-parada/add-zone-parada.page').then(m => m.AddZoneParadaPage),
    data: {
      type: 'zone'
    },
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'zones/add-parada',
    loadComponent: () => import('./pages/zones/pages/add-zone-parada/add-zone-parada.page').then(m => m.AddZoneParadaPage),
    data: {
      type: 'parada'
    },
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  }, {
    path: 'zones/edit-zone/:zoneId',
    loadComponent: () => import('./pages/zones/pages/add-zone-parada/add-zone-parada.page').then(m => m.AddZoneParadaPage),
    data: {
      type: 'zone'
    },
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  }, {
    path: 'zones/edit-parada/:paradaId',
    loadComponent: () => import('./pages/zones/pages/add-zone-parada/add-zone-parada.page').then(m => m.AddZoneParadaPage),
    data: {
      type: 'parada'
    },
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'metrics',
    loadComponent: () => import('./pages/metrics/metrics.page').then(m => m.MetricsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'history',
    loadComponent: () => import('./pages/history/history.page').then(m => m.HistoryPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'archive',
    loadComponent: () => import('./pages/archive/archive.page').then(m => m.ArchivePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'drivers',
    loadComponent: () => import('./pages/drivers/drivers.page').then(m => m.DriversPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'drivers/edit-driver/:id',
    loadComponent: () => import('./pages/drivers/pages/driver-detail/driver-detail.page').then(m => m.DriverDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'drivers/new-driver',
    loadComponent: () => import('./pages/drivers/pages/driver-detail/driver-detail.page').then(m => m.DriverDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'managers',
    loadComponent: () => import('./pages/managers/managers.page').then(m => m.ManagersPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'managers/new-manager',
    loadComponent: () => import('./pages/managers/pages/manager-detail/manager-detail.page').then(m => m.ManagerDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'managers/edit-manager/:id',
    loadComponent: () => import('./pages/managers/pages/manager-detail/manager-detail.page').then(m => m.ManagerDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'provinces',
    loadComponent: () => import('./pages/provinces/provinces.page').then(m => m.ProvincesPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'provinces/new-province',
    loadComponent: () => import('./pages/provinces/pages/province-detail/province-detail.page').then(m => m.ProvinceDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'provinces/edit-province/:id',
    loadComponent: () => import('./pages/provinces/pages/province-detail/province-detail.page').then(m => m.ProvinceDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/auth/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./pages/auth/reset-password/reset-password.page').then(m => m.ResetPasswordPage)
  },
  {
    path: 'create-driver/:register_token',
    loadComponent: () => import('./pages/auth/register/register.page').then(m => m.RegisterPage),
    data: {
      registerType: 'driver'
    }
  },
  {
    path: 'create-manager/:register_token',
    loadComponent: () => import('./pages/auth/register/register.page').then(m => m.RegisterPage),
    data: {
      registerType: 'manager'
    }
  },
  {
    path: 'action',
    loadComponent: () => import('./pages/auth/action/action.page').then(m => m.ActionPage)
  },
  {
    path: 'archive/new-document',
    loadComponent: () => import('./pages/archive/pages/document-detail/document-detail.page').then(m => m.DocumentDetailPage)
  },
  {
    path: 'archive/edit-document/:id',
    loadComponent: () => import('./pages/archive/pages/document-detail/document-detail.page').then(m => m.DocumentDetailPage)
  },
  {
    path: 'incidents',
    loadComponent: () => import('./pages/incidents/incidents.page').then(m => m.IncidentsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'incidents/new-incident',
    loadComponent: () => import('./pages/incidents/pages/incident-detail/incident-detail.page').then(m => m.IncidentDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'incidents/incident-detail/:id',
    loadComponent: () => import('./pages/incidents/pages/incident-detail/incident-detail.page').then(m => m.IncidentDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'sessions',
    loadComponent: () => import('./pages/sessions/sessions.page').then(m => m.SessionsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'sessions/session-detail/:id',
    loadComponent: () => import('./pages/sessions/pages/session-detail/session-detail.page').then(m => m.SessionDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'configuration',
    loadComponent: () => import('./pages/configuration/configuration.page').then(m => m.ConfigurationPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'delivery-system',
    loadComponent: () => import('./pages/delivery-system/delivery-system.page').then(m => m.DeliverySystemPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'delivery-system/new-delivery',
    loadComponent: () => import('./pages/delivery-system/pages/delivery-system-details/delivery-system-details.page').then(m => m.DeliverySystemDetailsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'delivery-system/edit-delivery/:id',
    loadComponent: () => import('./pages/delivery-system/pages/delivery-system-details/delivery-system-details.page').then(m => m.DeliverySystemDetailsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'control',
    loadComponent: () => import('./pages/control/control.page').then(m => m.ControlPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  }

];
