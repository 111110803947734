import { Component, Input, OnInit } from '@angular/core';
import { Control } from '@interfaces/control';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
})
export class ViewImageComponent implements OnInit {
  @Input() image?: string;
  @Input() control?: Control;
  parsedJson?: any;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    if (this.control) {
      this.parsedJson = JSON.parse(JSON.stringify(this.control));
      if (this.parsedJson?.image) {
        delete this.parsedJson.image;
      }
      delete this.parsedJson._id;
      delete this.parsedJson.bluetooth._id;
      delete this.parsedJson.storage._id;
      delete this.parsedJson.geo._id;
      delete this.parsedJson.service._id;
      delete this.parsedJson.driver._id;
      delete this.parsedJson.session._id;
      delete this.parsedJson.__v;
      delete this.parsedJson.updatedAt;
    }
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

}
