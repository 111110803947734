<div class="menu_box">
  <img class="logo" src="assets/images/logo_small.svg" alt="Mallorcab logo" routerLink="">
  <div class="lists">
    <ion-list>
      <ion-menu-toggle auto-hide="false" *ngFor="let page of pagesList; let i = index">
        <ng-container *ngIf="i < 11">
          <ion-button fill="clear" routerDirection="root" [routerLink]="'/' + page" lines="none" detail="false"
            routerLinkActive="selected" [routerLinkActiveOptions]="{exact : page ? false : true}">
            <div class="item_box">
              <ion-icon aria-hidden="true" [name]="'dp-' + (page || 'home')"></ion-icon>
              <div class="page_name">{{ 'menu.' + (page || 'home') | translate }}</div>
            </div>
          </ion-button>
        </ng-container>
      </ion-menu-toggle>
    </ion-list>

    <ion-list>
      <ion-menu-toggle auto-hide="false" *ngFor="let page of pagesList; let i = index">
        <ng-container *ngIf="i > 10">
          <ion-button fill="clear" routerDirection="root" [routerLink]="'/' + page" lines="none" detail="false"
            routerLinkActive="selected">
            <div class="item_box">
              <ion-icon aria-hidden="true" [name]="'dp-' + page"></ion-icon>
              <div class="page_name">{{ 'menu.' + page | translate }}</div>
            </div>
          </ion-button>
        </ng-container>
      </ion-menu-toggle>
      <ion-menu-toggle auto-hide="false">
        <ion-button fill="clear" lines="none" detail="false" routerLinkActive="selected" (click)="logout()">
          <div class="item_box">
            <ion-icon aria-hidden="true" [name]="'dp-close'"></ion-icon>
            <div class="page_name">{{ 'menu.logout' | translate }}</div>
          </div>
        </ion-button>
      </ion-menu-toggle>
    </ion-list>
  </div>
</div>