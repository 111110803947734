import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { GeneralSocket, NotificationSocket } from '@interfaces/socket';
import { User as DbUser } from '@interfaces/user';
import { User } from 'firebase/auth';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { ProvinceService } from './province.service';
import { RestService } from './rest.service';
import * as pako from 'pako';

const configMain: SocketIoConfig = { url: environment.socketMainUrl, options: { transports: ['websocket'] } };
const configCalculator: SocketIoConfig = { url: environment.socketCalculatorUrl, options: { transports: ['websocket'] } };

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {

  listnerMainInited = false;
  listnerCalculatorInited = false;
  user$: BehaviorSubject<DbUser | null> = new BehaviorSubject<DbUser | null>(null);

  private socketMain: Socket;
  private socketCalculator: Socket;

  constructor(
    private province: ProvinceService,
    private rest: RestService
  ) {
    this.socketMain = new Socket(configMain);
    this.socketCalculator = new Socket(configCalculator);
  }

  ngOnDestroy() {
    this.socketMain.disconnect();
    this.socketCalculator.disconnect();
  }

  async initSocket(user: User) {
    console.log('user', user);
    if (user) {
      const currentUser: DbUser | null = await this.rest.getCurrentUser();
      if (currentUser) {
        this.connectSocketMain(currentUser);
        this.connectSocketCalculator(currentUser);
      }
    } else {
      this.socketMain.disconnect();
      this.socketCalculator.disconnect();
    }
  }

  private connectSocketMain(user: DbUser) {
    const result = this.socketMain.connect();
    if (result.id) {
      this.registerMainUid(user);
    }

    if (!this.listnerMainInited) {
      this.listnerMainInited = true;
      this.socketMain.fromEvent('connect').subscribe(() => {
        console.log('Connected to socket main server! Socket ID:', this.socketMain.ioSocket.id);
        this.registerMainUid(user);
      });

      // List of listners
      this.listnerUpdateMainGeneral();
    }
  }

  private connectSocketCalculator(user: DbUser) {
    const result = this.socketCalculator.connect();
    if (result.id) {
      this.registerCalculatorUid(user);
    }

    if (!this.listnerCalculatorInited) {
      this.listnerCalculatorInited = true;
      this.socketCalculator.fromEvent('connect').subscribe(() => {
        console.log('Connected to socket calculator server! Socket ID:', this.socketCalculator.ioSocket.id);
        this.registerCalculatorUid(user);
      });

      // List of listners
      this.listnerUpdateCalculatorGeneral();
    }
  }

  private registerMainUid(user: DbUser) {
    console.log('registerUser Main', user);
    this.socketMain.emit('set-uid', { uid: user.uid, provinceId: user.province?.id?.toString() });
  }

  private registerCalculatorUid(user: DbUser) {
    console.log('registerUser Calculator', user);
    this.socketCalculator.emit('set-uid', { uid: user.uid, provinceId: user.province?.id?.toString() });
  }

  // private listnerJourney() {
  //   this.socketMain.on('journey', async (journey: Journey) => {
  //     console.log('!!! JOURNEY !!!', journey);
  //     if (journey) {
  //       console.log('Journey - Status', journey.status);

  //       switch (journey.status) {
  //         case ('DRIVER_FOUND'):
  //           await this.journeyService.getNewJourney(journey);
  //           break;
  //         // case 'CANCELED_BY_USER':
  //         //   // TODO: Show alert with indicate the client canceled journey
  //         //   // TODO: Get current journey in "Actual" page to refresh data
  //         //   break;
  //         default:
  //           this.journeyWatchService.nextJourney(journey);
  //           break;
  //       }
  //     }
  //   });
  // }

  // private listnerNotificationMain() {
  //   this.socketMain.on('notification', async (notification: NotificationSocket) => {
  //     console.log('!!! Notification !!!', notification);
  //     if (notification) {
  //       console.log('Notification', notification);
  //       await this.showNotification(notification);
  //     }
  //   });
  // }

  // private listnerNotificationCalculator() {
  //   this.socketCalculator.on('notification', async (notification: NotificationSocket) => {
  //     console.log('!!! Notification !!!', notification);
  //     if (notification) {
  //       console.log('Notification', notification);
  //       await this.showNotification(notification);
  //     }
  //   });
  // }

  private listnerUpdateCalculatorGeneral() {
    this.socketCalculator.on('province', async (generalInfo: GeneralSocket) => {
      const decompress = this.decompressData(generalInfo.data);
      if (decompress) {
        generalInfo.data = decompress;
      }
      console.log('!!! generalInfo !!!', generalInfo);
      if (generalInfo) {
        switch (generalInfo.type) {
          case 'ZONE_INFO':
            if (generalInfo.data) {
              this.province.updateZone(generalInfo.data);
            }
            break;
          case 'NOTIFICATION':
            await this.showNotification(generalInfo.data as NotificationSocket);
            break;
          default:
            console.error('Uknown socket general type');
            break;
        }
      }
    });
  }

  private listnerUpdateMainGeneral() {
    this.socketMain.on('province', async (generalInfoComp: any) => {
      const decompress = this.decompressData(generalInfoComp);
      if (decompress) {
        generalInfoComp = decompress;
      }
      const generalInfo: GeneralSocket = generalInfoComp;

      console.log('!!! generalInfo Main !!!', generalInfo);
      if (generalInfo) {
        switch (generalInfo.type) {
          case 'OFFERS':
            console.log('Journey list', generalInfo.data);
            break;

          case 'NOTIFICATION':
            console.log('New notification', generalInfo.data);
            await this.showNotification(generalInfo.data as NotificationSocket);
            break;

          case 'SERVICE_INFO':
            this.province.updateService(generalInfo.data);
            break;

          default:
            console.error('Uknown socket general type');
            break;
        }
      }
    });
  }

  async showNotification(data: NotificationSocket) {
    // const modalConfig = actionModalReadMore;
    // modalConfig.title = data.title;
    // modalConfig.subtitle = data.description;
    // modalConfig.buttons = [{
    //   id: 'read',
    //   cssClass: 'secondary',
    //   text: 'general.close'
    // }];
    // await this.modalService.openActionModal(modalConfig, true);
  }

  private decompressData(compressedData: ArrayBuffer) {
    try {
      const decompressed = pako.inflate(new Uint8Array(compressedData), { to: 'string' });
      return JSON.parse(decompressed);
    } catch (error) {
      return null;
    }
  }
}
