export const MapConfiguration =
{
  zoom: 13,
  styles: [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [
        { color: '#f5f5f5' }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        { visibility: 'off' }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#616161' }
      ]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        { color: '#f5f5f5' }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#bdbdbd' }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        { color: '#eeeeee' }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        { color: '#eeeeee' }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        { color: '#ffffff' }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#757575' }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        { color: '#dadada' }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#616161' }
      ]
    },
    {
      featureType: 'transit',
      stylers: [
        { visibility: 'off' }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        { color: '#c9c9c9' }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#9e9e9e' }
      ]
    },
  ]
}

// export const lineSymbol = {
//   path: "M 0,-1 0,1",
//   strokeOpacity: 1,
//   scale: 4,
//   strokeColor: '#7f8694'
// };

// export const polylineOptions = {
//   suppressMarkers: true,
//   polylineOptions: {
//     strokeOpacity: 0,
//     icons: [
//       {
//         icon: lineSymbol,
//         offset: "0",
//         repeat: "20px",
//       },
//     ],
//   },
// }
