import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Observable, from, mergeMap, throwError } from 'rxjs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(requestToHandle: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = requestToHandle.headers.has('skipAuth') ? (requestToHandle.headers.get('skipAuth') === 'true' ? true : false) : null;
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    if (skipIntercept) {
      const noAuth = requestToHandle.clone({ headers });
      return next.handle(noAuth);
    }

    return from(this.auth.getToken()).pipe(mergeMap(
      token => {
        // Create headers and set token header
        // headers = headers.append('Authorization', `Bearer ${token}`);
        // const authReq = requestToHandle.clone({ headers });

        // return next.handle(authReq);

        if (!skipIntercept && !token) {
          if (requestToHandle.url.includes('assets/i18n')) {
            const noAuth = requestToHandle.clone({ headers });
            return next.handle(noAuth);
          } else {
            return throwError(() => console.error('Authorization token is missing', requestToHandle.url));
          }
        } else {
          // Create headers and set token header
          headers = headers.append('Authorization', `Bearer ${token}`);
          const authReq = requestToHandle.clone({ headers });

          return next.handle(authReq);
        }
      }
    ));
  }
}
