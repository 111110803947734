import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ZoneInfo, Coordinates, Zone, TriggerCoordinates } from '@interfaces/rest/interfaces/zone';
// import { RadioModal } from '@components/radio-modal/radio-modal.component';

import { User } from '@interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { ProvinceService } from '@services/province.service';
import { MapConfiguration } from '@static_data/maps';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-google-map-general',
  templateUrl: './google-map-general.component.html',
  styleUrls: ['./google-map-general.component.scss'],
})
export class GoogleMapGeneralComponent implements OnInit {
  @Input() user?: User;
  selectedFilter = 'all';

  centerMap?: Coordinates;

  firstTime = true;

  @ViewChild('map') mapElem?: any;

  // Map
  map?: google.maps.Map;
  driversMarkers: Map<number, google.maps.Marker> = new Map();
  driverMarker?: google.maps.Marker;
  zonesDraws: google.maps.Polygon[] = [];

  availableZones: Zone[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private province: ProvinceService
  ) {
    this.province.zoneInfo$.pipe(
      takeUntil(this.destroy$))
      .subscribe(async zoneInfo => {
        if (zoneInfo) {
          this.checkEnableZones(zoneInfo);
          this.drawDrivers();
        }
      })
  }

  async ngOnInit() {
    if (!this.centerMap) {
      this.centerMap = await this.province.getCenterMap();
    }
    this.loadMap();
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkEnableZones(zoneInfo: ZoneInfo) {
    this.availableZones = zoneInfo.zones.filter(elem => elem?.information);
    if (this.zonesDraws.length === 0) {
      this.setZones();
    }
  }

  drawZones(coordinates: Coordinates[], index: number) {
    const colors = [
      '#6ca974', '#150b9e', '#158803', '#cd574e', '#3d4056',
      '#e85186', '#7c25bc', '#d92354', '#ff0640', '#2bbaad',
      '#787291', '#2d1b1c', '#ee8abb', '#ded547', '#a7d3e2'
    ];
    var polygon = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: colors[index],
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: '#000000',
      fillOpacity: 0
    });

    // Add the polygon to the map
    polygon.setMap(this.map!);
    this.zonesDraws.push(polygon);
  }

  setZones() {
    if (this.map) {
      let index = 0;
      this.availableZones.forEach(zone => {
        if (zone?.coordinates) {
          this.drawZones(zone.coordinates, index);
          index += 1;
          if (zone.triggerCoordinates) {
            const coordinates = this.getTriggerTime(zone.triggerCoordinates)
            if (coordinates) {
              this.drawZones(this.getTriggerPoligon(coordinates), index);
              index += 1;
            }
          }
        }
      });
    }
  }

  getTriggerTime(triggerCoordinates: TriggerCoordinates[]) {
    let coordinates: Coordinates | undefined = undefined;
    triggerCoordinates.forEach(triggerPoint => {
      const triggerTime = triggerPoint.time;
      const [startTime, endTime] = triggerTime.split('_');

      const now = new Date();
      const startDate = new Date(now);
      const endDate = new Date(now);

      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);
      startDate.setHours(startHours, startMinutes, 0, 0);
      endDate.setHours(endHours, endMinutes, 0, 0);

      if (now >= startDate && now <= endDate) {
        coordinates = triggerPoint.coordinates;
      }
    });
    return coordinates;
  }

  getTriggerPoligon(center: Coordinates, distance = 50): Coordinates[] {
    // Convertir distancia de metros a grados aproximados
    let latDistance = distance / 111320;
    let lngDistance = distance / (111320 * Math.cos(center.lat * (Math.PI / 180)));

    // Calcular las esquinas del cuadrado
    let topLeft = { lat: center.lat + latDistance, lng: center.lng - lngDistance };
    let topRight = { lat: center.lat + latDistance, lng: center.lng + lngDistance };
    let bottomRight = { lat: center.lat - latDistance, lng: center.lng + lngDistance };
    let bottomLeft = { lat: center.lat - latDistance, lng: center.lng - lngDistance };

    // Devolver el polígono como un arreglo de puntos
    return [topLeft, topRight, bottomRight, bottomLeft, topLeft]; // Cerramos el polígono añadiendo el punto inicial al final
  }

  loadMap() {
    const mapOptions = MapConfiguration;
    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElem.nativeElement, {
        center: { lat: this.centerMap!.lat, lng: this.centerMap!.lng },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        clickableIcons: false,
        disableDefaultUI: true,
        ...mapOptions,
      });
    }, 0);

  }

  async drawDrivers() {
    let drivers: User[] | undefined = await this.province.getDrivers();
    if (!this.map || !drivers) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    const newIds = new Set(drivers.map(driver => driver.driverId));

    drivers.forEach(driver => {
      if (this.selectedFilter === driver.status || this.selectedFilter === 'all') {
        this.createOrUpdateMarker(driver);

        if (this.firstTime) {
          const position = new google.maps.LatLng(driver.coords.lat, driver.coords.lng);
          bounds.extend(position);
        }
      }
    });

    this.driversMarkers.forEach((marker, id) => {
      const driver = drivers?.find(driver => driver.driverId === id);
      if (!driver || this.selectedFilter !== driver.status && this.selectedFilter !== 'all') {
        marker.setMap(null);
        this.driversMarkers.delete(id);
      }

      if (!newIds.has(id)) {
        marker.setMap(null);
        this.driversMarkers.delete(id);
      }
    });

    if (this.firstTime) {
      this.map.fitBounds(bounds);
      this.firstTime = false;
    }
  }

  private createOrUpdateMarker(driver: User): void {
    if (!driver.driverId) {
      return;
    }
    let marker = this.driversMarkers.get(driver.driverId);

    // console.error('driver', driver);
    const labelContentActive: google.maps.MarkerLabel = {
      text: driver.license?.taxiNumber?.toString() || '',
      className: 'custom-marker-label active'
    };
    const labelContentOccupied: google.maps.MarkerLabel = {
      text: driver.license?.taxiNumber?.toString() || '',
      className: 'custom-marker-label occupied'
    };

    if (marker) {
      // update existing marker
      marker.setPosition(new google.maps.LatLng(driver.coords.lat, driver.coords.lng));
      marker.setLabel(driver.status === 'active' ? labelContentActive : labelContentOccupied);
    } else {
      // Create new marker
      marker = new google.maps.Marker({
        map: this.map,
        label: driver.status === 'active' ? labelContentActive : labelContentOccupied,
        position: new google.maps.LatLng(driver.coords.lat, driver.coords.lng),
        icon: this.getIconForStatus()
      });

      const extra = driver.license?.extra?.map(elem => this.translate.instant('services.' + elem))?.join(', ') || '';
      const content = `Nombre: ${driver.name}<br>Matricula: ${driver.license?.registrationNumber}<br>Coche: ${driver.license?.brand} ${driver.license?.model}<br>Extras: ${extra}`;
      const infoWindow = new google.maps.InfoWindow({ content });

      marker.addListener('click', () => {
        this.openInfo(driver);
        infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });

      this.driversMarkers.set(driver.driverId, marker);
    }

  }

  async openInfo(driver: User) {
    console.log('driver', driver);
    // await this.modalService.openCarModal('car_modal', {
    //   name: driver.name || '',
    //   taxiNumber: driver.license?.taxiNumber || '',
    //   registrationNumber: driver.license?.registrationNumber || '',
    //   brand: driver.license?.brand || '',
    //   model: driver.license?.model || '',
    //   extra: driver.license?.extra || [],
    //   status: driver.status || '',
    //   sessionId: driver.sessionId || 0,
    //   zone: driver.zoneInfo.info?.zoneId ? await this.province.getZoneNameById(driver.zoneInfo.info.zoneId) : undefined,
    //   parada: driver.zoneInfo.info?.paradaId ? await this.province.getZoneNameById(driver.zoneInfo.info.paradaId) : undefined,
    //   zoneTime: driver.zoneInfo.info?.enterZone,
    //   paradaTime: driver.zoneInfo.info?.enterParada
    // });
  }

  private getIconForStatus(): string {
    return '/assets/icons/pin.png';
  }
}
