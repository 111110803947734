import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Zone } from '@interfaces/rest/interfaces/zone';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';

@Component({
  selector: 'app-home-search-bar',
  templateUrl: './home-search-bar.component.html',
  styleUrls: ['./home-search-bar.component.scss'],
})
export class HomeSearchBarComponent implements OnInit {
  searchParams = {
    zone: '',
    status: '',
    searchValue: ''
  };
  @Input() zones?: Zone[];
  @Input() status = ['occupied', 'active'];
  @Output() searchWithParams = new EventEmitter();

  constructor(private utils: DpUtilsService) { }

  ngOnInit() { }

  search(ev?: any) {
    this.searchParams.status = (this.searchParams.status === 'all') ? '' : this.searchParams.status;
    this.searchParams.zone = (this.searchParams.zone === 'all') ? '' : this.searchParams.zone;

    if (ev?.detail?.value || ev?.detail?.value === '') {
      this.searchParams.searchValue = ev.detail.value;
    }

    this.searchWithParams.emit(this.searchParams);
  }

}
