import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { Journey } from '@interfaces/rest/interfaces/journey';
import { Session, SessionCoordenates } from '@interfaces/rest/interfaces/sessions';
import { Coordinates } from '@interfaces/rest/interfaces/zone';
import { User } from '@interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { ProvinceService } from '@services/province.service';
import * as moment from 'moment';

// declare let google: any;
type PageType = 'distance' | 'list';

export const carMarkerIcon = 'assets/icons/map/car.png';
export const taxiMarkerIcon = 'assets/icons/map/taxi.svg';
export const notificationMarkerIcon = 'assets/icons/map/notification.svg';
export const startMarkerIcon = 'assets/icons/map/start.svg';
export const startClientMarkerIcon = 'assets/icons/map/start_client.svg';
export const endMarkerIcon = 'assets/icons/map/end.svg';
export const endClientMarkerIcon = 'assets/icons/map/end_client.svg';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnChanges {
  // @Input() drivers?: DriverInfo[] = [];
  @Input() driversbyTaxi?: User[] = [];
  @Input() session?: Session;
  @Input() journey?: Journey;
  @Input() type?: PageType = 'distance';
  @Input() filter?: any;
  profSelected = 'normal';

  @ViewChild('map') mapElem?: any;

  zoom = 13;
  map?: google.maps.Map;
  markers: google.maps.Marker[] = [];
  infoWindows: google.maps.InfoWindow[] = [];

  centerMap?: Coordinates;

  constructor(private translate: TranslateService,
    private province: ProvinceService
  ) { }

  async ngOnChanges() {
    if (!this.centerMap) {
      this.centerMap = await this.province.getCenterMap();
    }

    if (this.driversbyTaxi?.length) {
      this.initDriversByTaxiMap();
    } else if (this.journey) {
      this.initMapByJourney();
    } else if (this.session) {
      this.initSessionMap();
    }
  }

  // initMap() {
  //   console.log('init Map', this.map);
  //   if (!this.map) {
  //     const myLatLng = { lat: 39.56939, lng: 2.65024 };
  //     this.map = new google.maps.Map(document.getElementById('map')!, {
  //       zoom: 10,
  //       center: myLatLng,
  //       // zoom_changed: false,
  //     });
  //   } else {
  //     console.log('Clear markers');
  //     this.clearMarkersAndInfoWindows();
  //     // this.currentCenter = this.map.getCenter();
  //     // this.currentZoom = this.map.getZoom();
  //   }

  //   this.createMarkersAndInfoWindows(this.map);
  // }

  clearMarkersAndInfoWindows() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });

    this.markers = [];
    this.infoWindows.forEach(infoWindow => {
      infoWindow.close();
    });

    this.infoWindows = [];
  }

  // createMarkersAndInfoWindows(map?: google.maps.Map) {
  //   if (!this.map) {
  //     return;
  //   }
  //   if (this.drivers?.length) {
  //     this.drivers.forEach((driver, index) => {
  //       if (driver.coordinates?.lat && driver.coordinates?.lng) {
  //         const marker = new google.maps.Marker({
  //           position: { lat: driver.coordinates.lat, lng: driver.coordinates.lng },
  //           map,
  //           clickable: true,
  //           // icon: {
  //           //   url: 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"%3E%3C/svg%3E',
  //           //   scaledSize: new google.maps.Size(1, 1),
  //           // },
  //         });

  //         this.markers.push(marker);

  //         const infoWindow = new google.maps.InfoWindow({ content: this.createMarkerHtml(index, driver.status, driver?.license?.taxiNumber!) });
  //         infoWindow.open(map, marker);
  //         this.infoWindows.push(infoWindow);

  //         google.maps.event.addListener(infoWindow, 'domready', () => {
  //           const info = document.getElementById(`google-map-car-${index}`);
  //           if (info) {
  //             const parent = info.parentElement?.parentElement?.parentElement?.parentElement;
  //             if (parent) {
  //               parent.classList.add('car-info');
  //             }
  //             info.addEventListener('click', async () => {
  //               this.presentAlert(driver);
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
  // }

  initDriversByTaxiMap() {
    const map = new google.maps.Map(document.getElementById('map')!, {
      zoom: 4,
      center: this.centerMap,
    });
    if (this.driversbyTaxi?.length) {
      this.driversbyTaxi.forEach((driver, index) => {
        if (driver?.lat && driver?.lng) {
          const marker = new google.maps.Marker({
            position: { lat: driver.lat, lng: driver.lng },
            map,
            icon: 'assets/icons/general/dp-driver-locate.svg'
          });
        }
      });

    }
  }

  initMapByJourney() {
    if (this.journey?.fromLat && this.journey?.fromLng && this.journey?.toLat && this.journey?.toLng) {
      setTimeout(() => {
        this.map = new google.maps.Map(this.mapElem.nativeElement, {
          center: { lat: this.journey?.fromLat || 0, lng: this.journey?.fromLng || 0 },
          zoom: this.zoom,
          disableDefaultUI: true,
          fullscreenControl: true
        });

        let points = this.journey?.routes?.map(elem => new google.maps.LatLng(elem.lat, elem.lng));
        let polyline = new google.maps.Polyline({
          path: points,
          geodesic: true, // si quieres que la línea siga la curvatura de la Tierra
          strokeColor: '#FF0000', // color de la línea
          strokeOpacity: 1.0, // opacidad de la línea
          strokeWeight: 2 // grosor de la línea
        });
        polyline.setMap(this.map);

        this.journey?.history?.forEach(elem => {
          let url = '';
          let text = '';
          if (elem.status === 'DRIVER_CONFIRMED') {
            url = taxiMarkerIcon;
            text = 'Confirmado por taxista';
          } else if (elem.status === 'TRIP_STARTED') {
            url = startClientMarkerIcon;
            text = 'Inicio del viaje';
          } else if (elem.status === 'TRIP_COMPLETED') {
            url = endMarkerIcon;
            text = 'Fin de servicio';
          } else if (elem.status === 'USER_NOTIFICATION') {
            url = notificationMarkerIcon;
            text = 'Usuario notificado';
          } else if (elem.status === 'CHANGE_DRIVER') {
            url = startClientMarkerIcon;
            text = 'Cambio del conductor';
          }
          if (elem.coordinates && url) {
            this.setMarket(new google.maps.LatLng(elem.coordinates.lat, elem.coordinates.lng), url, text);
          }
        });

        if (this.journey?.fromLat && this.journey.fromLng) {
          this.setMarket(new google.maps.LatLng(this.journey.fromLat, this.journey.fromLng), startMarkerIcon, 'Punto inicio marcado por cliente');
        }

        if (this.journey?.toLat && this.journey.toLng) {
          this.setMarket(new google.maps.LatLng(this.journey.toLat, this.journey.toLng), endClientMarkerIcon, 'Punto final marcado por cliente');
        }

        if (this.journey?.status !== 'TRIP_COMPLETED' && this.journey?.driver?.lat && this.journey?.driver?.lng) {
          this.setMarket(new google.maps.LatLng(this.journey.driver.lat, this.journey.driver.lng), endClientMarkerIcon, 'Posicion actual del taxi', this.map, this.journey.driver.heading, this.journey.driver.speed);
          // new google.maps.Marker({
          //   position: { lat: this.journey.driver.lat, lng: this.journey.driver.lng },
          //   map: this.map,
          //   icon: 'assets/icons/general/dp-driver-locate.svg'
          // });
        }
      }, 0);

    }
  }

  setMarket(position: google.maps.LatLng, url: string, content: string, map?: google.maps.Map, rotation?: number, speed?: number) {
    let icon: google.maps.Icon | google.maps.Symbol = { url, scaledSize: new google.maps.Size(32, 32) };
    if (rotation !== undefined) {
      icon = {
        path: 'M -4,0 0,0 4,0 0,-10 z',
        fillColor: (speed || 0) > 100 ? 'red' : '#EBA747',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: 'black',
        scale: 3,
        rotation
      }
    }
    const marker = new google.maps.Marker({
      position,
      icon,
      map: map ? map : this.map
    });

    const infoWindow = new google.maps.InfoWindow({ content });

    marker.addListener('mouseover', () => {
      infoWindow.open({
        anchor: marker,
        map: this.map,
        shouldFocus: false,
      });
    });

    marker.addListener('mouseout', () => {
      infoWindow.close();
    });
  }

  initSessionMap() {
    const map = new google.maps.Map(document.getElementById('map')!, {
      zoom: 14,
      center: this.centerMap,
    });

    const pathCoordinates: {
      lat: number;
      lng: number;
    }[] = [];
    let previousTime: any | undefined = undefined;


    if (this.session && this.session.coordinates) {

      const coordsList = this.filterCoords();

      coordsList.forEach(coordinates => {
        if (coordinates?.lat && coordinates?.lng) {
          pathCoordinates.push({ lat: coordinates.lat, lng: coordinates.lng });
          const currentTime = moment(coordinates.createdAt);
          if (!previousTime || currentTime.diff(previousTime, 'seconds') >= 60) {
            let content = `Fecha: ${moment(coordinates.createdAt).format('DD/MM/YYYY HH:mm')}<br>Velocidad: ${Math.round(coordinates.speed || 0)} km/h`;
            if (coordinates.journeyId) {
              content += `<br>Servicio: ${coordinates.journeyId}`
            }
            if (coordinates.driverStatus) {
              content += `<br>Estado: ${this.translate.instant('sessions.' + coordinates.driverStatus)}`
            }
            this.setMarket(new google.maps.LatLng(coordinates.lat, coordinates.lng), carMarkerIcon, content, map, coordinates.heading, coordinates.speed);
            previousTime = moment(coordinates.createdAt);
          }

        }
      });
      const path = new google.maps.Polyline({
        path: pathCoordinates,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 4,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 2,
            },
            offset: '100%'
          },
        ],
      });

      path.setMap(map);
    }
  }

  filterCoords() {
    let coordsList: SessionCoordenates[] = JSON.parse(JSON.stringify(this.session!.coordinates));
    if (this.filter.dateFrom && this.filter.dateTo) {
      const startDate = new Date(this.filter.dateFrom).getTime();
      const endDate = new Date(this.filter.dateTo).getTime(); // Corregido para usar dateTo
      coordsList = coordsList.filter(elem =>
        new Date(elem.createdAt!).getTime() >= startDate &&
        new Date(elem.createdAt!).getTime() <= endDate
      );
    }
    if (this.filter.status) {
      coordsList = coordsList.filter(elem => elem.driverStatus === this.filter.status);
    }
    if (this.filter.journey === 'yes') {
      coordsList = coordsList.filter(elem => elem.journeyId);
    } else if (this.filter.journey === 'no') {
      coordsList = coordsList.filter(elem => !elem.journeyId);
    }

    return coordsList;
  }

  // async presentAlert(driver: DriverInfo) {
  //   const alert = await this.alertController.create({
  //     header: driver.license?.taxiNumber,
  //     subHeader: this.translate.instant('home.status') + this.translate.instant('home.' + driver.status),
  //     message: driver.user ? (this.translate.instant('home.driver') + driver.user.name) : '',
  //     buttons: ['OK']
  //   });

  //   await alert.present();
  // }

  // createMarkerHtml(index: number, status: string, carModel: string): HTMLDivElement {
  //   const content = document.createElement('div');
  //   content.className = 'google-map-car-marker-container';
  //   content.id = `google-map-car-${index}`;
  //   const statusDiv = document.createElement('div');
  //   statusDiv.className = 'status';
  //   statusDiv.classList.add(status);
  //   const carModelDiv = document.createElement('div');
  //   carModelDiv.className = 'car-model';
  //   carModelDiv.textContent = carModel;
  //   content.appendChild(statusDiv);
  //   content.appendChild(carModelDiv);
  //   return content;
  // }


}