import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

import { filter } from 'rxjs/operators';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  isInit = false;
  started = false;
  constructor(
    private router: Router
  ) {
  }

  async start(autorized = false) {
    if (autorized) {
      await this.initFb();
      console.log('ENABLE ANALITICAS');
    }

    if (!this.started) {
      this.started = true;
      this.router.events.pipe(
        filter((e: (Event | any)) => e instanceof NavigationEnd)
      ).subscribe((e: RouterEvent) => {
        this.setScreenName(e.url);
      });
    }
  }

  async initFb() {
    console.log('Init firebase cookies');
    const resp: any = await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    if (resp) {
      this.isInit = true;
    }
  }

  logEvent(name = '', params = {}) {
    if (this.isInit) {
      FirebaseAnalytics.logEvent({ name, params });
    }
  }

  setScreenName(screenName: string) {
    if (this.isInit) {
      FirebaseAnalytics.setScreenName({ screenName });
    }
  }

  sendEventByType(name: AnalyticType, params: ParamType = {}) {
    if (this.isInit) {
      FirebaseAnalytics.logEvent({ name, params });
    }
  }
}

export interface ParamType {
  currentUserId?: string | number;
  type?: string;
  id?: string;
}

export type AnalyticType =
  'login' |
  'recover_password' |
  'logout' |
  'search_licenses' |
  'search_journey' |
  'edit_journey' |
  'create_journey' |
  'delete_journey' |
  'search_license' |
  'create_license' |
  'delete_license' |
  'edit_license' |
  'edit_client' |
  'delete_client' |
  'search_client' |
  'search_message' |
  'create_message' |
  'delete_message' |
  'search_zone' |
  'create_zone' |
  'delete_zone' |
  'edit_zone' |
  'search_driver' |
  'create_driver' |
  'delete_driver' |
  'edit_driver' |
  'search_province' |
  'create_province' |
  'delete_province' |
  'edit_province' |
  'search_incident' |
  'edit_incident' |
  'create_delivery' |
  'edit_delivery' |
  'delete_delivery' |
  'view_session_detail' |
  'delete_session' |
  'search_session' |
  'create_documentation' |
  'view_documentation' |
  'edit_documentation' |
  'delete_documentation'
  ;



