<div class="alert_confirm_box">
  <ion-icon class="close_icon" name="dp-close" (click)="close()"></ion-icon>
  <ion-icon class="alert_icon" *ngIf="icon" [name]="icon"></ion-icon>
  <div class="message">{{translatePath + message | translate: {default: message} }}</div>
  <div class="buttons_box">
    <ion-button class="cancel" mode="ios" fill="clear" size="small" (click)="close(true)">
      {{'general.yes_sure' | translate}}</ion-button>
    <ion-button class="secondary" mode="ios" fill="clear" size="small" (click)="close()">
      {{'general.no_cancel' | translate}}</ion-button>
  </div>
</div>