<div class="container">
  <div class="header">
    <div class="left_side">
      <div *ngIf="license?.taxiNumber" class="license">Licencia: {{ license?.taxiNumber }}</div>
      <div *ngIf="license?.circleCounter" class="license">Vuelta: {{ license?.circleCounter }}</div>
    </div>
    <ion-button (click)="openRefund()" fill="clear" class="general">
      Nuevo ajuste
    </ion-button>
  </div>
  <app-table [columns]="columns" [lines]="lines" (doActionParent)="doActionParent($event)" [pagination]="true"
    [total]="totalPages" [currentPage]="page" (paginacionParent)="page = $event; getWheelActivity()"
    (sendSort)="sendSort($event)">
  </app-table>
</div>