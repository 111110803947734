<div class="container" *ngIf="id; else isNew">
  <div class="title">
    {{ refund! < 0 ? ('Retirar ' + (refund! * -1) + ' vuelta') : 'Añadir ' + refund + ' vuelta' }} </div>
      <ion-textarea placeholder="Describa la razón" class="general" [(ngModel)]="refundNote" [rows]="4"></ion-textarea>
      <div class="bottom_area">
        <ion-button (click)="doAction()" fill="clear" class="{{ refund! < 0 ? 'cancel' : 'success' }}">
          {{ refund! < 0 ? 'Retirar' : 'Añadir' }} </ion-button>
      </div>
  </div>

  <ng-template #isNew>
    <div class="container">
      <div class="title">Añadir el ajuste en la rueda de la licencia <span>nº{{ taxiNumber }}</span></div>
      <ion-input placeholder="Numero del servicio(Opcional)" class="general" type="number"
        [(ngModel)]="journey"></ion-input>
      <ion-input placeholder="Cantidad subtrair(ej.: -3) o añadir(ej.: 4)" class="general" type="number"
        [(ngModel)]="refund"></ion-input>
      <ion-textarea placeholder="Describa la razón" class="general" [(ngModel)]="refundNote" [rows]="4"></ion-textarea>
      <div class="bottom_area">
        <ion-button (click)="doAction()" fill="clear" class="general">
          Ajustar
        </ion-button>
      </div>
    </div>

  </ng-template>