<div class="home_search_box">
  <div class="tools">
    <ion-searchbar id="home-custom-search" (ionInput)="search($event)" debounce="1000"
      [placeholder]="'home.find_license' | translate"></ion-searchbar>
    <ion-select interface="popover" mode="md" class="general" [placeholder]="'home.zones' | translate"
      (ionChange)="search()" [(ngModel)]="searchParams.zone">
      <ion-select-option value="all">{{'home.all' | translate}}
      </ion-select-option>
      <ion-select-option *ngFor="let zone of zones" [value]="zone._id">{{zone.name}}</ion-select-option>
    </ion-select>

    <ion-select interface="popover" mode="md" class="general" [placeholder]="'home.states' | translate"
      (ionChange)="search()" [(ngModel)]="searchParams.status">
      <ion-select-option value="all">{{'home.all' | translate}}
      </ion-select-option>
      <ion-select-option *ngFor="let stat of status" [value]="stat">{{'home.' + stat | translate}}
      </ion-select-option>
    </ion-select>

  </div>
</div>