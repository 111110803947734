import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() items!: any[];
  @Input() activeIndex = 0;
  @Output() sendActiveIndex: EventEmitter<number> = new EventEmitter();

  constructor() { }

  selectIndex(i: number) {
    if (i < this.activeIndex) {
      this.sendActiveIndex.emit(i);
    }
  }

}
