import { Component, Input, OnInit } from '@angular/core';
import { ActionWheelActivityComponent } from '@components/action-wheel-activity/action-wheel-activity.component';
import { Table } from '@interfaces/others';
import { License, WheelActivity } from '@interfaces/rest/interfaces/licenses';
import { ModalController } from '@ionic/angular';
import { RestService } from '@services/rest.service';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';

@Component({
  selector: 'app-wheel-list',
  templateUrl: './wheel-list.component.html',
  styleUrls: ['./wheel-list.component.scss'],
})
export class WheelListComponent implements OnInit {
  @Input() licenseId!: number;
  license?: License;
  lines: WheelActivity[] = [];
  columns: Table[] = [
    {
      field: 'createdAt',
      name: 'createdAt',
      type: 'datetime',
      width: 20,
      sort: true
    }, {
      field: 'journey',
      name: 'journey',
      width: 7
    }, {
      field: 'actionType',
      name: 'actionType',
      width: 10,
      translate: 'wheel_activity'
    }, {
      field: 'countCicle',
      name: 'countCicle',
      width: 7
    }, {
      field: 'note',
      name: 'note',
      width: 10
    }, {
      field: 'refund',
      name: 'refund',
      width: 7
    }, {
      field: 'refundNote',
      name: 'refundNote',
      width: 10
    }, {
      field: 'refundDate',
      name: 'refundDate',
      type: 'datetime',
      width: 10
    }, {
      field: 'refundCountCicle',
      name: 'refundCountCicle',
      width: 10
    }, {
      field: 'action',
      name: '',
      width: 10,
      type: 'actions',
      actions: [
        { type: 'view' },
        { type: 'edit' }
      ]
    }
  ];

  sortParams = { field: 'createdAt', sortType: 'desc' };

  page = 1;
  totalPages = 1;

  constructor(private rest: RestService,
    private utils: DpUtilsService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    await this.getWheelActivity();
    await this.getLicense();
  }

  async getWheelActivity() {
    await this.utils.showLoading();
    const searchParams: any = {};
    if (this.sortParams?.sortType) {
      searchParams.sort_by = `${this.sortParams.field}:${this.sortParams.sortType}`;
    }
    const result = await this.rest.getWheelActivity(this.licenseId!, this.page, searchParams);
    if (result.ok && result.response?.data?.result?.length) {
      this.lines = result.response.data.result;
      this.lines = this.lines.map(elem => ({ ...elem, color: elem.action > 0 ? 'green' : 'red' }));
      this.totalPages = result.response.data.totalPages || 1;
    }
    await this.utils.hideLoading();
  }

  async getLicense() {
    const result = await this.rest.getLicenseById(this.licenseId);
    if (result.ok) {
      this.license = result.response;
    }
  }

  async doActionParent(event: any) {
    console.log('event.action', event.line);
    if (event.action === 'view' && event.line.journey) {
      console.log('Do view');
      window.open(`/services/service-detail/${event.line.journey}`, '_blank');
    } else if (event.action === 'edit' && event.line.id) {
      console.log('Do edit');
      await this.editWheelActivity(event.line.id, event.line.action)
    }
  }

  async editWheelActivity(id: number, action: number) {
    const modal = await this.modalController.create({
      component: ActionWheelActivityComponent,
      componentProps: { id, refund: (action * -1) }
    });

    await modal.present();
    const result = await modal.onWillDismiss();
    if (result.data?.refresh) {
      await this.getWheelActivity();
      await this.getLicense();
    }
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async openRefund() {
    const modal = await this.modalController.create({
      component: ActionWheelActivityComponent,
      componentProps: { license: this.licenseId, taxiNumber: this.license?.taxiNumber }
    });

    await modal.present();
    const result = await modal.onWillDismiss();
    if (result.data?.refresh) {
      await this.getWheelActivity();
      await this.getLicense();
    }
  }

  async sendSort(ev: any) {
    console.log('ev', ev);
    this.sortParams = ev;
    await this.utils.showLoading();
    this.page = 1;
    await this.getWheelActivity();
    await this.utils.hideLoading();
  }
}
