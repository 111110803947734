import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';

@Pipe({
  name: 'timeElapsed'
})
export class TimeElapsedPipe implements PipeTransform {

  constructor(private utils: UtilsService) { }

  transform(date: Date): string {
    return this.utils.getCurrentTime(date, true);
  }
}
