import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FilterInputs, FilterToolbarConfig } from '@interfaces/filters-toolbar';
import { SuggestionLocation } from '@interfaces/others';
import { IonDatetime } from '@ionic/angular';
import { Destination, LocationService } from '@services/location.service';

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent {
  sidesList: string[] = ['left', 'center', 'right'];
  currentLocationSuggestions: Destination[] = [];
  destinationSuggestions: Destination[] = [];
  inputHasFocus?: string;

  @Input() filterToolbarConfig?: FilterToolbarConfig | any;

  @Output() btnAction: EventEmitter<any> = new EventEmitter();
  @Output() selectAction: EventEmitter<FilterInputs> = new EventEmitter();

  @ViewChild(IonDatetime, { static: false }) datetime?: IonDatetime;

  uniqueNumber = Math.random();

  constructor(private locationService: LocationService, private cd: ChangeDetectorRef) {
  }

  buttonAction() {
    this.btnAction.emit();
  }

  selectEvent(ev: any, input: FilterInputs) {
    input.selectedValue = ev.detail.value;
    this.selectAction.emit(input);
  }

  async getSuggestions(type: 'from' | 'to', ev: any) {
    this.currentLocationSuggestions = [];
    this.destinationSuggestions = [];
    switch (type) {
      case 'from':
        this.currentLocationSuggestions = await this.locationService.geolocation(ev.detail.value);
        console.log('this.currentLocationSuggestions', this.currentLocationSuggestions);
        if (ev?.detail?.value?.length < 5) {
          //TODO:
          // this.serviceForm.get('currentLocation')?.setValue(
          //   {
          //     lat: undefined,
          //     lng: undefined,
          //     main: '',
          //     secondary: ''
          //   }
          // );
          // this.sendData.emit(this.serviceForm.value);
        }
        break;

      case 'to':
        this.destinationSuggestions = await this.locationService.geolocation(ev.detail.value);
        console.log('destinationSuggestions', this.destinationSuggestions);
        if (ev?.detail?.value?.length < 5) {
          //TODO:
          // this.serviceForm.get('destination')?.setValue(
          //   {
          //     lat: undefined,
          //     lng: undefined,
          //     main: '',
          //     secondary: ''
          //   }
          // );
          // this.sendData.emit(this.serviceForm.value);
        }
        break;
    }

    console.log(this.currentLocationSuggestions);
    console.log(this.destinationSuggestions);
  }

  // async getAddressByPlaceId(suggestion: Destination) {
  //   const suggestionResult = await this.locationService.getAddressByPlaceId(suggestion.placeId);
  //   console.log('suggestionResult*****', suggestionResult);
  //   if (suggestionResult) {
  //     const location = {
  //       lat: suggestionResult.lat,
  //       lng: suggestionResult.lng,
  //       main: suggestion.main,
  //       secondary: suggestion.secondary
  //     };
  //     if (this.inputHasFocus === 'from') {

  //       this.filterToolbarConfig.left.inputs.forEach((input: FilterInputs) => {
  //         if (input.title === 'from') {
  //           input.selectedValue = location.lat + ', ' + location.lng
  //         }
  //       });
  //       // this.serviceForm.get('currentLocation')?.setValue(location);
  //       // this.serviceForm.get('currentLocationName')?.setValue(`${location.main}, ${location.secondary}`);
  //       this.currentLocationSuggestions = [];
  //     } else {
  //       this.filterToolbarConfig.left.inputs.forEach((input: FilterInputs) => {
  //         if (input.title === 'to') {
  //           input.selectedValue = location.lat + ', ' + location.lng
  //         }
  //       });
  //       // this.serviceForm.get('destination')?.setValue(location);
  //       // this.serviceForm.get('destinationName')?.setValue(`${location.main}, ${location.secondary}`);
  //       this.destinationSuggestions = [];
  //     }
  //   }

  //   // this.sendData.emit(this.serviceForm.value);
  // }


  async saveSuggestion(location: SuggestionLocation) {
    if (this.inputHasFocus === 'from') {

      this.filterToolbarConfig.left.inputs.forEach((input: FilterInputs) => {
        if (input.title === 'from') {
          input.selectedValue = location.lat + ', ' + location.lng
        }
      });

      this.currentLocationSuggestions = [];
    } else {
      this.filterToolbarConfig.left.inputs.forEach((input: FilterInputs) => {
        if (input.title === 'to') {
          input.selectedValue = location.lat + ', ' + location.lng
        }
      });
      this.destinationSuggestions = [];
    }
  }

}
