import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Control } from '@interfaces/control';
import { DeliverySystem } from '@interfaces/delivery-system';
import { DriverInfo } from '@interfaces/driver-info';
import { Province } from '@interfaces/province';
import { Configuration } from '@interfaces/rest/interfaces/configuration';
import { CreateDocumentationDto, Documentation, EditDocumentationDto } from '@interfaces/rest/interfaces/documentations';
import { CreateDriverDto, EditDriverDto } from '@interfaces/rest/interfaces/drivers';
import { CreateIncidentDto, EditIncidentDto, GetIncidentDto, Incident } from '@interfaces/rest/interfaces/incidents';
import { ChangeFromAddress, ChangeToAddress, CreateJourneyDto, EditJourneyDto, FindJourneyDto, GetJourneyDto, Journey } from '@interfaces/rest/interfaces/journey';
import { ActionType, CreateLicenseDto, EditLicenseDto, License, OwnershipLicenseDto, WheelActivity } from '@interfaces/rest/interfaces/licenses';
import { CreateManagerDto, EditManagerDto } from '@interfaces/rest/interfaces/manager';
import { Message } from '@interfaces/rest/interfaces/message';
import { CreateNotificationDto, EditNotificationDto } from '@interfaces/rest/interfaces/notifications';
import { PaginationParams } from '@interfaces/rest/interfaces/paginationParams';
import { CreateProvinceDto, EditProvinceDto } from '@interfaces/rest/interfaces/province';
import { CreateSessionDto, EditSessionDto, Session } from '@interfaces/rest/interfaces/sessions';
import { DeleteUserQuery, EditUserDto, SignupDto, SignupSocialDto } from '@interfaces/rest/interfaces/user';
import { CreateZoneDto, Zone } from '@interfaces/rest/interfaces/zone';
import { User } from '@interfaces/user';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DpRestService, DpStorageService, DpUtilsService } from 'digitup-lib/dist/digitup-lib';

export interface GeneralResponse<Type> {
  ok: boolean;
  response: Type;
}

export interface GeneralResponseData<Type> {
  data: {
    result: Type,
    totalElementsByCurrentFilter?: number,
    totalPages?: number
  }
  total: number;
}

export interface GeneralResponseDataTotal<Type> {
  ok: boolean;
  response: {
    data: Type,
    total: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class RestService {
  apiMainUrl = environment.apiMainUrl;
  apiCalculatorUrl = environment.apiCalculatorUrl;

  constructor(
    private rest: DpRestService,
    private storage: DpStorageService,
    private utils: DpUtilsService,
    private nav: NavController,
    private translate: TranslateService
  ) { }

  // Health
  async getHealthAll(): Promise<GeneralResponse<any>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/health`);
  }

  // Users
  async signup(data: SignupDto): Promise<GeneralResponse<User>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/users/auth/signup`, data, true);
  }

  async signupSocial(data: SignupSocialDto): Promise<GeneralResponse<User>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/users/auth/signup/social`, data, true);
  }

  async getUsersAll(params?: PaginationParams): Promise<GeneralResponse<GeneralResponseData<User[]>>> {
    if (params && !params.sort_by) {
      params.sort_by = 'id:desc';
    }
    return await this.rest.generalGet(`${this.apiMainUrl}/users`, params, false);
  }

  async deleteUser(data: DeleteUserQuery): Promise<GeneralResponse<User>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/users`, data)
  }

  async getCurrentUser(force = false): Promise<User | null> {
    const user = await this.storage.getItem('taxi_backoffice_current_user');
    if (user && !force) {
      return user;
    } else {
      const apiUser = await this.rest.generalGet(`${this.apiMainUrl}/users/currentUser`);
      if (apiUser.ok && apiUser.response) {
        if (apiUser.response.role?.includes('admin') || apiUser.response.role?.includes('manager')) {
          await this.storage.setItem('taxi_backoffice_current_user', apiUser.response);
          return apiUser.response;
        } else {
          await this.utils.showToast({ message: this.translate.instant('auth.error.user_role_error'), cssClass: 'danger' });
          this.nav.navigateRoot('/login');
          return null;
        }

      } else {
        return null;
      }
    }
  }

  async getUserRecoveryPasswordLink(email: string): Promise<string> {
    return await this.rest.generalGet(`${this.apiMainUrl}/users/recovery-link/${email}`, {}, false)
  }

  async getUser(id: number): Promise<GeneralResponse<User>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/users/by-id/`, { id });
  }

  async setConfirmEmailLink(email: string): Promise<GeneralResponse<null>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/users/confirm-link`, { email })
  }

  async forgotPassword(email: string): Promise<GeneralResponse<null>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/users/auth/forgot-password`, { email })
  }

  async restoreUser(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/users/restore`, { id })
  }

  async disableUser(id: number): Promise<GeneralResponse<User>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/users/disable`, { id })
  }

  async editUser(id: number, data: EditUserDto): Promise<GeneralResponse<null>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/users/edit`, data, { id })
  }

  async setUserManager(id: number) {
    return await this.rest.generalPatch(`${this.apiMainUrl}/users/set-manager`, {}, { id })
  }

  async registerUser(data: any) {
    return await this.rest.generalPost(`${this.apiMainUrl}/users`, data, true);
  }

  async setClientHotel(userId: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/users/set-client-hotel/?userId=${userId}`,);
  }

  async unsetClientHotel(userId: number): Promise<GeneralResponse<User>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/users/unset-client-hotel/?userId=${userId}`,);
  }

  // Managers
  async createManager(data: CreateManagerDto): Promise<GeneralResponse<User>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/managers`, data);
  }

  async getManagersAll(): Promise<GeneralResponse<null>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/managers`);
  }

  async registerManager(token: string, params: { password: string }) {
    return await this.rest.generalPost(`${this.apiMainUrl}/managers/${token}/register`, params);
  }

  async editManager(id: number, data: EditManagerDto) {
    return await this.rest.generalPut(`${this.apiMainUrl}/managers/${id}`, data);
  }

  async deleteManager(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/managers/${id}`);
  }

  async getManagerById(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/managers/${id}`);
  }

  async disableManager(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/managers/${id}/disable`);
  }

  async enableManager(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/managers/${id}/enable`);
  }

  async setProvinceToManger(managerId: number, provinceId: number) {
    return await this.rest.generalPut(`${this.apiMainUrl}/managers/${managerId}/${provinceId}`);
  }

  // Province
  async createProvince(data: CreateProvinceDto): Promise<GeneralResponse<Province>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/province/`, data);
  }

  async getProvincesAll(): Promise<GeneralResponse<Province[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/province/`);
  }

  async editProvince(id: string, data: EditProvinceDto): Promise<GeneralResponse<Province>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/province/${id}`, data);
  }

  async deleteProvince(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/province/${id}`);
  }

  async getProvinceById(id: number | string): Promise<GeneralResponse<Province>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/province/${id}`);
  }

  async getManagersByProvinceId(id: number) {
    return await this.rest.generalGet(`${this.apiMainUrl}/province/${id}/managers`);
  }

  async getLicensesByProvinceId(id: number) {
    return await this.rest.generalGet(`${this.apiMainUrl}/province/${id}/licenses`);
  }

  async getDriversByProvinceId(id: number) {
    return await this.rest.generalGet(`${this.apiMainUrl}/province/${id}/drivers`);
  }

  // Drivers
  async createDriver(data: CreateDriverDto): Promise<GeneralResponse<User>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/drivers`, data);
  }

  async getDriversAll(params?: PaginationParams): Promise<GeneralResponse<User[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/drivers`, params);
  }

  async registerDriver(token: string, params: { password: string }) {
    return await this.rest.generalPost(`${this.apiMainUrl}/drivers/${token}/register`, params);
  }

  async editDriver(id: number, data: EditDriverDto): Promise<GeneralResponse<null>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/drivers/${id}`, data);
  }

  async deleteDriver(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/drivers/${id}`);
  }

  async deleteDriverFromLicense(driverId: number, licenseId: number) {
    return await this.rest.generalDelete(`${this.apiMainUrl}/drivers/${driverId}/license/${licenseId}`);
  }

  async getDriversByClientId(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/drivers/${id}/client`);
  }


  async addDriverToLicense(driverId: number, licenseId: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/drivers/${driverId}/license/${licenseId}`);
  }

  // Licenses
  async getLicensesAll(params?: PaginationParams): Promise<GeneralResponse<GeneralResponseData<License[]>>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/licenses`, params)
  }

  async createLicense(data: CreateLicenseDto): Promise<GeneralResponse<License>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/licenses`, data);
  }

  async getLicenseById(id: number): Promise<GeneralResponse<License>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/licenses/${id}`);
  }

  async editLicense(id: number, data: EditLicenseDto): Promise<GeneralResponse<License>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/licenses/${id}`, data);
  }

  async addOwnershipLicense(id: number, data: OwnershipLicenseDto): Promise<GeneralResponse<License>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/licenses/${id}`, data);
  }

  async deleteOwnershipLicense(id: number, data: OwnershipLicenseDto): Promise<GeneralResponse<License>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/licenses/${id}`, data);
  }

  async deleteLicense(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/licenses/${id}`);
  }

  // Journey
  async createJourney(data: CreateJourneyDto): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/journey`, data);
  }

  async cancelTotalJourney(journeyId: number, reason: string) {
    const data: any = { journeyId }
    if (reason) {
      data.reason = reason;
    }
    return await this.rest.generalPatch(`${this.apiMainUrl}/journey/driver/cancel-journey/total`, data);
  }

  async getJourneysAll(params?: GetJourneyDto): Promise<GeneralResponse<GeneralResponseData<Journey[]>>> {
    if (params && !params.sort_by) {
      params.sort_by = 'id:desc';
    }
    return await this.rest.generalGet(`${this.apiMainUrl}/journey`, params);
  }

  async editJourney(id: number, data: EditJourneyDto): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/journey/${id}`, data);
  }

  async deleteJourney(id: number) {
    return await this.rest.generalDelete(`${this.apiMainUrl}/journey/${id}`);
  }

  async getJourneyById(id: number): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/journey/${id}`);
  }

  async getJourneyByUserId(id: number) {
    return await this.rest.generalGet(`${this.apiMainUrl}/journey/user/${id}`);
  }

  async getJourneyByDriverId(id: number) {
    return await this.rest.generalGet(`${this.apiMainUrl}/journey/driver/${id}`);
  }

  async getJourneyByLicenseId(id: number): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/journey/license/${id}`);
  }

  async findJourney(data: FindJourneyDto) {
    return await this.rest.generalPost(`${this.apiMainUrl}/journey/find`, data);
  }

  async createRecurrentReserve(data: any) {
    return await this.rest.generalPost(`${this.apiMainUrl}/journey/recurrent-reserves`, data);
  }

  async getJourneyDriversByProvinceId(provinceId?: number): Promise<GeneralResponse<DriverInfo[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/journey/drivers-info`, { provinceId });
  }

  async getJourneyZonesByProvinceId(params: { provinceId: number }): Promise<GeneralResponse<DriverInfo[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/journey/zones-info`, params);
  }

  async getZoneInfoByProvinceId(provinceId: number): Promise<GeneralResponse<any>> {
    return await this.rest.generalGet(`${this.apiCalculatorUrl}/sessions/zone-info?provinceId=${provinceId}`);
  }

  async changeFromAddress(data: ChangeFromAddress): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/journey/change-address`, data);
  }

  async changeToAddress(data: ChangeToAddress): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/journey/change-address`, data);
  }

  // Sesions
  async createSession(data: CreateSessionDto): Promise<GeneralResponse<Session>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/sessions`, data);
  }

  async getSessionsAll(params: { limit: number, page: number, sort_by?: string }) {
    if (!params.sort_by) {
      params.sort_by = 'id:desc'
    }
    return await this.rest.generalGet(`${this.apiMainUrl}/sessions`, params)
  }

  async editSession(id: number, data: EditSessionDto): Promise<GeneralResponse<Session>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/sessions/${id}`, data);
  }

  async deleteSession(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/sessions/${id}`);
  }

  async getSessionById(id: number): Promise<GeneralResponse<Session>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/sessions/by-id`, { id });
  }

  async closeSessionById(id: number) {
    return await this.rest.generalPatch(`${this.apiMainUrl}/sessions/close/${id}`);
  }

  async getSocketStatus() {
    return await this.rest.generalGet(`${this.apiMainUrl}/sessions/check-socket`);
  }

  // Documents
  async createDocumentation(data: CreateDocumentationDto): Promise<GeneralResponse<Documentation>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/documentations`, data);
  }

  async editDocumentation(data: EditDocumentationDto): Promise<GeneralResponse<Documentation>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/documentations`, data);
  }

  async getDocumentationsAll(): Promise<GeneralResponse<Documentation[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/documentations`);
  }

  async deleteDocumentation(id: number): Promise<GeneralResponse<null>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/documentations/${id}`);
  }

  async getDocumentationById(id: number): Promise<GeneralResponse<Documentation>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/documentations/${id}`);
  }

  // Incidents
  async createIncident(data: CreateIncidentDto): Promise<GeneralResponse<Incident>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/incidents`, data, true)
  }

  async getIncidentsByUserId(params: GetIncidentDto): Promise<GeneralResponse<GeneralResponseData<Incident[]>>> {
    if (!params.sort_by) {
      params['sort_by'] = 'id:desc';
    }
    return await this.rest.generalGet(`${this.apiMainUrl}/incidents`, params);
  }

  async getIncidentById(id: number): Promise<GeneralResponse<Incident>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/incidents/${id}`);
  }

  async editIncidentById(id: number, data: EditIncidentDto): Promise<GeneralResponse<Incident>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/incidents/${id}`, data);
  }

  async deleteIncidentById(id: number) {
    return await this.rest.generalDelete(`${this.apiMainUrl}/incidents/${id}`);
  }

  // Notifications
  async createNotification(data: CreateNotificationDto): Promise<GeneralResponse<Notification>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/notifications`, data);
  }

  async getNotificationById(id: number): Promise<GeneralResponse<Notification>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/notifications/${id}`);
  }

  async editNotification(id: number, data: EditNotificationDto): Promise<GeneralResponse<Notification>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/notifications/${id}`, data);
  }

  async deleteNotification(id: number): Promise<GeneralResponse<Notification>> {
    return await this.rest.generalPut(`${this.apiMainUrl}/notifications/${id}`);
  }

  async getNotificationsByUserId(id: number): Promise<GeneralResponse<Notification[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/notifications/user/${id}`);
  }

  //ZONES

  async createZone(data: CreateZoneDto): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalPost(`${this.apiCalculatorUrl}/zone`, data);
  }

  async getZoneById(zoneId: number): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalGet(`${this.apiCalculatorUrl}/zone/${zoneId}`);
  }

  async deleteZone(zoneId: string): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalDelete(`${this.apiCalculatorUrl}/zone/${zoneId}`);
  }

  async getZoneByProvinceId(provinceId: number): Promise<GeneralResponse<Zone[]>> {
    const params = { all: true };
    return await this.rest.generalGet(`${this.apiCalculatorUrl}/zone/province/${provinceId}`, params);
  }

  async changeQueuePosition(mongoSessionId: string) {
    return await this.rest.generalPatch(`${this.apiCalculatorUrl}/zone/change-queue-position`, { mongoSessionId });
  }

  async addParadaToZone(zoneId: number, data: CreateZoneDto): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalPatch(`${this.apiCalculatorUrl}/zone/${zoneId}`, data);
  }

  async editZone(zoneId: number, data: CreateZoneDto): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalPatch(`${this.apiCalculatorUrl}/zone/${zoneId}`, data);
  }

  async changeZoneStatus(zoneId: number, data: { status: boolean }): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalPatch(`${this.apiCalculatorUrl}/zone/change-status/${zoneId}?status=${data.status}`);
  }

  async changeZoneParadaStatus(paradaId: number, status: boolean): Promise<GeneralResponse<Zone>> {
    return await this.rest.generalPatch(`${this.apiCalculatorUrl}/zone/parada/change-status/${paradaId}?status=${status}`);
  }

  async deleteParada(paradaId: string): Promise<GeneralResponse<any>> {
    return await this.rest.generalDelete(`${this.apiCalculatorUrl}/zone/parada/${paradaId}`);
  }

  //MESSAGES

  async getMessagesByUserId(params: { userId?: number, page?: number, limit?: number }) {
    return await this.rest.generalGet(`${this.apiMainUrl}/messages`, params);
  }

  async getAllMessages() {
    return await this.rest.generalGet(`${this.apiMainUrl}/messages/chats/current-user`);
  }

  async sendMessagesAllDrivers(params: { text: string, hiddenMessage: boolean, all: boolean }) {
    return await this.rest.generalPost(`${this.apiMainUrl}/messages/all-drivers`, params);
  }

  async sendMessagesByLicenseId(licenseId: number, params: { text: string, hiddenMessage: boolean }) {
    return await this.rest.generalPost(`${this.apiMainUrl}/messages/license/${licenseId}`, params);
  }

  async createMessage(params: { to: number, text: string, hiddenMessage: boolean }) {
    return await this.rest.generalPost(`${this.apiMainUrl}/messages`, params);
  }

  async deleteMessage(messageId: string): Promise<GeneralResponse<Message>> {
    return await this.rest.generalDelete(`${this.apiCalculatorUrl}/messages/${messageId}`);
  }

  async deleteChatWithUser(userId: number): Promise<GeneralResponse<Message>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/messages/chat-with-user?userId=${userId}`);
  }


  // CONFIGURATIONS

  async getConfigurations(id: number): Promise<GeneralResponse<Configuration>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/configuration/${id}`);
  }

  async editConfigurations(id: number, data: { status: boolean }): Promise<GeneralResponse<Configuration>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/configuration/${id}`, data);
  }

  // DELIVERY SYSTEM
  async getDeliverySystems(provinceId: number): Promise<GeneralResponse<DeliverySystem[]>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/delivery-system`, { provinceId });
  }

  async getDeliverySystemById(id: string): Promise<GeneralResponse<DeliverySystem>> {
    return await this.rest.generalGet(`${this.apiMainUrl}/delivery-system/${id}`);
  }

  async updateDeliverySystem(id: string, data: DeliverySystem): Promise<GeneralResponse<any>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/delivery-system/${id}`, data);
  }

  async createDeliverySystem(data: DeliverySystem): Promise<GeneralResponse<DeliverySystem>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/delivery-system`, data);
  }

  async deleteDeliverySystem(id: number): Promise<GeneralResponse<any>> {
    return await this.rest.generalDelete(`${this.apiMainUrl}/delivery-system/${id}`);
  }

  async resetWheel(id: string): Promise<GeneralResponse<any>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/licenses/reset-wheel-counter/${id}`);
  }

  async sendNewVersion(version: string): Promise<GeneralResponse<any>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/configuration/send-update-apk?version=${version}`);
  }

  async getWheelActivity(id: number, page = 1, sortParams: any): Promise<GeneralResponse<GeneralResponseData<WheelActivity[]>>> {
    const params = {
      limit: 10,
      page,
      'where.license': id
    }
    const mergedParams = { ...params, ...sortParams };
    return await this.rest.generalGet(`${this.apiMainUrl}/wheelActivity`, mergedParams);
  }

  async updateWheelActivity(id: number, params: { refund: number, refundNote: string }): Promise<GeneralResponse<WheelActivity>> {
    return await this.rest.generalPatch(`${this.apiMainUrl}/wheelActivity/refund/${id}`, params);
  }

  async addWheelActivity(params: { licenseId: number, refund: number, refundNote: string, actionType: ActionType, journeyId?: number }): Promise<GeneralResponse<WheelActivity>> {
    return await this.rest.generalPost(`${this.apiMainUrl}/wheelActivity/add-refund`, params);
  }

  async requestControlData(uid?: number, province?: number) {
    let params = '';
    if (uid) {
      params += `uid=${uid}`;
    }
    if (province) {
      params += `province=${province}`;
    }
    return await this.rest.generalPost(`${this.apiCalculatorUrl}/control/send-control?${params}`);
  }

  async getControls(): Promise<GeneralResponse<Control[]>> {
    return await this.rest.generalGet(`${this.apiCalculatorUrl}/control`);
  }
}

