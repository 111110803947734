<div class="table" *ngIf="columns && lines">
  <div class="header">
    <div *ngIf="showCheck" class="header_column">
      <ion-checkbox></ion-checkbox>
    </div>
    <div class="header_column" [ngStyle]="{'width.%': column.width}" *ngFor="let column of columns; let i = index">
      <span *ngIf="column.name">{{ 'table.' + column.name | translate:{default: column.name} }}</span>
      <ion-icon *ngIf="column.sort" class="sort_icon" [ngClass]="{
          'asc': sortParams?.field === column.field && sortParams?.sortType === 'asc',
          'desc': sortParams?.field === column.field && sortParams?.sortType === 'desc',
          'sort_icon': true
        }" name="swap-vertical-outline" (click)="applySort(i)"></ion-icon>
    </div>
  </div>
  <div class="table_content" [style]="'background-color: ' + background" [class.noBorderRadius]="limit">
    <ng-container *ngFor="let line of lines; let i = index">
      <div *ngIf="limit ? i < limit : i < 99999" class="line {{ line?.color }}">
        <div class="cell col-{{column.field}} {{column.class}} " [ngStyle]="{ 'width.%': column.width }"
          *ngFor="let column of columns">

          <!-- Sin type -->
          <!-- <div class="cell_value" *ngIf="!column.type">
            {{ line[column!.field]}}
          </div> -->

          <!-- Varios valores yes -> Si, no -> No -->
          <div class="cell_value" *ngIf="column.type === 'multiVal' ">
            {{ column?.values?.[line[column.field]] }}
          </div>

          <!-- Varios valores con colores -->
          <div class="cell_value multiValColors" *ngIf="column.type === 'multiValColors' ">
            <div class="value {{value.name}} {{value.color}}" *ngFor="let value of line[column!.field]">
              {{value.translatePath + '.' + value.name | translate:{default: value.name} }}</div>
          </div>

          <!-- Boolean valiues??? MultiVal? -->
          <div class="cell_value" *ngIf="column.type === 'boolean'">
            {{ line[column.field] === true ? 'Si' : 'No' }}
          </div>

          <!-- BASIC FIELD -->
          <div class="cell_value" *ngIf="!column.type && column.field !== 'action'">
            <!-- {{ column.translate ? (column.translate + '.' + parseString(line, column.field) | translate) :
            parseString(line, column.field) }} -->
            <span [innerHTML]="column.translate ? (column.translate + '.' + parseString(line, column.field) | translate) :
            parseString(line, column.field)"></span>
            {{ column.extraText }}
          </div>

          <!-- DATE TYPE -->
          <div class="cell_value" *ngIf="column.type === 'date'">
            {{ (line[column.field] && line[column.field].seconds ? (line[column.field].seconds * 1000) :
            line[column.field]) | date:'dd \'de\' MMMM yyyy' }}
          </div>

          <!-- DATETIME TYPE -->
          <div class="cell_value" *ngIf="column.type === 'datetime'">
            {{ (line[column.field] && line[column.field].seconds ? (line[column.field].seconds * 1000) :
            line[column.field]) | date:'d/M/yyyy, HH:mm' }}
          </div>

          <!-- DATETIME TYPE -->
          <div class="cell_value" *ngIf="column.type === 'datetimeseconds'">
            {{ (line[column.field] && line[column.field].seconds ? (line[column.field].seconds * 1000) :
            line[column.field]) | date:'d/M/yyyy, HH:mm:ss' }}
          </div>

          <!-- LABEL TYPE -->
          <div class="cell_value" *ngIf="column.type === 'label'">
            <div class="general_label" [class]="line?.[column!.field]">
              {{ column.translate ? (column.translate + '.' + parseString(line, column.field) | translate) :
              parseString(line, column.field) }}
              {{ column.extraText }}
            </div>
          </div>

          <div class="cell_value" *ngIf="column.type === 'toggle'">
            <ion-toggle [checked]="line[column!.field]" (ionChange)="toggleEvent($event, line, column!.field)">
            </ion-toggle>
          </div>

          <div class="cell_value" *ngIf="column.type === 'string'">
            <ion-input [value]="line[column!.field]" (ionInput)="setString($event, line, column!.field)"></ion-input>
          </div>


          <div class="cell_status" *ngIf="column.type === 'status'">
            <!--<div class="dot"></div>-->
            <div class="status {{parseString(line, column.field)}}">
              {{ column.translate ? (column.translate + '.' + parseString(line, column.field) | translate) :
              parseString(line, column.field) }}
              {{ column.extraText }}
            </div>
          </div>

          <div class="actions" *ngIf="column.field === 'action'">
            <div *ngFor="let action of column.actions"
              [class.disabled]="action.disabledField && !line[action.disabledField]" class="action"
              [class.hidden]="action.hideField && !line[action.hideField]"
              (click)="doAction(action.type, line, $event)">
              <ion-icon [src]="'/assets/icons/table/' + action.type + '.svg'"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>



<div *ngIf="pagination && pagesList?.length" class="pagination_box">
  <ion-button class="secondary back" mode="ios" fill="clear" size="small" (click)="changePage(-1)"
    [disabled]="(currentPage && (currentPage <= 1))">
    <ion-icon name="dp-back"></ion-icon>
  </ion-button>
  <div class="pages">
    <ion-button class="next general" mode="ios" fill="clear" size="small">{{currentPage}}
    </ion-button>
  </div>
  <ion-button class="secondary next" mode="ios" fill="clear" size="small" (click)="changePage(+1)"
    [disabled]="currentPage === total">
    <ion-icon name="dp-back"></ion-icon>
  </ion-button>
</div>

<!-- <div *ngIf="pagination && pagesList?.length" class="pagination_box">
  <ion-button class="secondary back" mode="ios" fill="clear" size="small" (click)="changePage(-1)"
    [disabled]="(currentPage && (currentPage <= 1))">
    <ion-icon name="dp-back"></ion-icon>
  </ion-button>
  <div class="pages">
    <ion-button *ngFor="let page of pagesList" class="next {{currentPage === page ? 'general' : 'secondary'}}"
      mode="ios" fill="clear" size="small" (click)="changePage(page, true)">{{page}}
    </ion-button>
  </div>
  <ion-button class="secondary next" mode="ios" fill="clear" size="small" (click)="changePage(+1)"
    [disabled]="currentPage === total">
    <ion-icon name="dp-back"></ion-icon>
  </ion-button>
</div> -->