import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-toolbar',
  templateUrl: './edit-toolbar.component.html',
  styleUrls: ['./edit-toolbar.component.scss'],
})
export class EditToolbarComponent {
  @Input() isEdit = false;
  @Input() lite = false;
  @Output() saveAction = new EventEmitter();
  @Output() editAction = new EventEmitter();
  @Output() deleteAction = new EventEmitter();

  constructor() { }

  action(action: 'edit' | 'save' | 'delete') {
    switch (action) {
      case 'save':
        this.saveAction.emit();
        break;
      case 'edit':
        this.editAction.emit(this.isEdit);
        break;

      case 'delete':
        this.deleteAction.emit();
        break;
    }
  }


}
