import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestService } from '@services/rest.service';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';

@Component({
  selector: 'app-action-wheel-activity',
  templateUrl: './action-wheel-activity.component.html',
  styleUrls: ['./action-wheel-activity.component.scss'],
})
export class ActionWheelActivityComponent implements OnInit {

  @Input() id?: number;
  @Input() refund?: number;
  @Input() license?: number;
  @Input() taxiNumber?: number;
  refundNote?: string;
  journey?: number;

  constructor(private rest: RestService, private utils: DpUtilsService,
    private modalController2: ModalController) { }

  ngOnInit() { }

  async doAction() {
    await this.utils.showLoading();
    if (this.id) {
      this.updateActivity();
    } else {
      this.createActivity();
    }
    await this.utils.hideLoading();
  }

  async updateActivity() {
    if (!this.refundNote) {
      await this.utils.showToast({ message: 'Debera de indicar la razón', cssClass: 'warning' });
      return;
    }
    const data = {
      refund: this.refund!,
      refundNote: this.refundNote
    }
    const result = await this.rest.updateWheelActivity(this.id!, data);
    if (result.ok) {
      await this.utils.showToast({ message: 'Cambios guardados' });
    }
    await this.modalController2.dismiss({ refresh: true });
  }

  async createActivity() {
    if (!this.refund || !this.refundNote) {
      await this.utils.showToast({ message: 'Debera de indicar la razón y valor que quiere añadir o quitar', cssClass: 'warning' });
      return;
    }
    const data: any = {
      refund: this.refund,
      refundNote: this.refundNote,
      license: this.license,
      actionType: 'refund'
    }

    if (this.journey) {
      data.journey = this.journey;
    }
    const result = await this.rest.addWheelActivity(data);
    if (result.ok) {
      await this.utils.showToast({ message: 'Cambios guardados' });
    }
    await this.modalController2.dismiss({ refresh: true });
  }

  async dismiss() {
    await this.modalController2.dismiss();
  }
}
